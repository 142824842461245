.rd_popup{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
   z-index: 1000;
    height: 100vh;
    background-color: rgb(0, 0,0,0.2);
    backdrop-filter: blur(8px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.rd_popup_inner{
    position: relative;
    padding: 32px;
    width: 100%;
    max-width: 640px;
    background-color: #fff200;
    border-radius: 20px;
}

.rd_popup_inner .rdpop_close_btn{
    position: absolute;
    top: 16px;
    right: 16px;
    font-size: 14px;
    cursor: pointer;

}