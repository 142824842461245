.popup{
    position: fixed;
    top: 50px    ;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgb(0, 0,0,0.2);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(8px);
}

.popup_inner{
    position: relative;
    padding: 22px;

    width: 100%;
    max-width: 840px;
    background-color: #fff200;
    border-radius: 10px;

}

.popup_inner .close_btn{
    position: absolute;
    top: 16px;
    right: 16px;
    font-size: 14px;
    cursor: pointer;
}