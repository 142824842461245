.outlined-btn {
  border: none ;
  background-color: #fff;
  color: #000;
  padding: 10px 20px;
  font-size: 14px;
  font-family: 'Neuwelt';
  text-decoration: none;
  cursor: pointer;
  text-decoration: none;
  border-radius: 7%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  box-shadow: 0 0 5px #fff200;
  transition: box-shadow 0.3s ease;
  transition: background-color 0.9s ease, box-shadow 1s ease;
  animation: slideInDown 2s
}
.outlined-btn:hover {
  background-color: #000;
  color: #fff;
  box-shadow: 0 0 10px #000,
  0 0 20px #fff200;
}
/* .fotter-sub{
  background: #fff200;
  width: 100%;
  height: 212px;
  left: 0px;
  top: 35px; 
  position: relative; 
  padding: 180px 60px;
  display: flex;
  justify-Content: center;
  align-Items: center;
  border-radius: 20px;
  
} */
.Group182{
  width: 100%;
  height: 212px;
  left: 0px;
  top: 35px; 
  position: relative; 
  padding: 180px 60px;
  display: flex;
  justify-Content: center;
  align-Items: center;
  border-radius: 20px;
}
.Group182-mobile{
 display: flex;
 flex-direction: column;

}
.footer_right_hm{
  height: 40px;
  background: #000;
  position: relative;
  display: flex;
  justify-Content:center;
  align-Content:center;
}
.footer_Right_txt_hm {
  top: 10px;
  position: relative;
  color: #6C727F; 
  font-Size: 12px;
  font-Family: 'Neuwelt';
  font-Weight: 400;
  
}
.fotter-icon-desktop{
  padding-right: 85%;
  color: #000;  
  height: 55px;
}
.fotter-icon-mobile{
  display: flex;
  flex-direction: column;
  padding-top:70px;
 
  width: 50vw;
  margin-left: 80px;
}
.footer_Sub_txt1-desktop{
  
  height: 250px;
  position: absolute;
}
.footer_Sub_txt1-mobile{
  height: 250px;
  padding-top: 10px;
}
.footer_Sub_txt_1_1-desktop{
  text-align: center;
  right: 0px;
  left: -260px;
  font-family: 'Neuwelt';
  position: absolute;
  color: #000;
  font-Size: 30px; 
  font-Weight: 700 ;
  width: 160px;

}
.footer_Sub_txt_1_1-mobile{
  text-align: center;
  font-family: 'Neuwelt';
  padding-top: 70px;
  left: 80px;
  position: absolute;
  color: #000;
  font-Size: 20px; 
  font-Weight: 700 ;
  width: 70px;
}

.footer_Sub_txt_5_5-desktop{
  text-align: center;
  right: 0px;
  left: -350px;
  font-family: 'Neuwelt';
  position: absolute;
  color: #000;
  font-Size: 30px; 
  font-Weight: 700 ;
  width: 160px;

}
.footer_Sub_txt_5_5-mobile{
  text-align: center;
  font-family: 'Neuwelt';
  padding-top: 70px;
  left: 80px;
  position: absolute;
  color: #000;
  font-Size: 20px; 
  font-Weight: 700 ;
  width: 70px;
}
.footer_Sub_para-desktop{
  padding-top: 75px;
  right: 0px;
  left: -300px;
  position: absolute;
  color: #000;
  font-Size: 15px; 
  width: 380px;
}
.footer_Sub_para-mobile{
  padding-top: 120px;
  padding-left: 0px;
  position: absolute;
  color: #000;
  font-Size: 15px; 
  width: 320px;
}

.footer_Sub_para_5-desktop{
  padding-top: 75px;
  right: 0px;
  left: -390px;
  position: absolute;
  color: #000;
  font-Size: 15px; 
  width: 380px;
}
.footer_Sub_para_5-mobile{
  padding-top: 120px;
  padding-left: 0px;
  position: absolute;
  color: #000;
  font-Size: 15px; 
  width: 320px;
}



.footer_Sub_txt_2-desktop{
  height: 250px;
  position: absolute;
}
.footer_Sub_txt_2-mobile{
  height: 200px;
  padding-top: 10px;
}

.footer_Sub_txt_3-desktop{
  height: 250px;

  position: absolute;
}
.footer_Sub_txt_3-mobile{
  height: 200px;
  padding-top: 10px;
}

.footer_Sub_txt_2_2-desktop{
  text-align: center;
  right: 0px;
  left: -20px;
  position: absolute;
  color: #000;
  font-Size: 30px; 
  font-Weight: 700 ;
  width: 160px;

}
.footer_Sub_txt_2_2-mobile{
  text-align: center;

  padding-top: 25px;
  left: 80px;
  position: absolute;
  color: #000;
  font-family: 'Neuwelt';
  font-Size: 20px; 
  font-Weight: 700 ;
  width: 70px;
}
.footer_Sub_para_2-desktop{
  padding-top: 75px;
  left: -55px;
  position: absolute;
  color: #000;
  font-Size: 15px; 
  width: 320px;
}
.footer_Sub_para_2-mobile{
  padding-top: 75px;


  position: absolute;
  color: #000;
  font-Size: 15px; 
  width: 320px;
}
.footer_icons{
  position: relative;
  margin-right: 50px;
  top: 0;
  color: #000;
  font-size:20px;
}




.footer_Sub_txt_5-desktop{
  padding-right: 400px;
  height: 250px;
  position: absolute;
}
.footer_Sub_txt_5-mobile{
  height: 250px;
  padding-top: 10px;
}

.footer_Sub_txt_3_3-desktop{
 text-align: center;
 padding-left: 100px;
 font-family: 'Neuwelt';

  left: 340px;
  position: absolute;
  color: #000;
  font-Size: 28px; 
  font-Weight: 700 ;
  width: 45vh;

}
.footer_Sub_txt_3_3-mobile{
   text-align: center;

   padding-top: 30px;
   
   font-family: 'Neuwelt';
   color: #000;
   font-Size: 20px; 
   font-Weight: 700 ;
   width: 40vh;
}
.footer_Sub_para_3-desktop{
  padding-top: 75px;
  padding-left: 190px;
  right: 0px;
  left: 240px;
  position: absolute;
  color: #000;
  font-Size: 15px; 
  width: 60vh;
}
.footer_Sub_para_3-mobile{
  padding-top: 20px;
  

  position: absolute;
  color: #000;
  font-Size: 15px; 
  width:320px;
}






.socialmedia {
  width: 400px;
  top: 250px;
  left: 60px;
  height: 100px;
  position: absolute;
  color: #fff;
}
.SubscribeUs{
  
  left: 0;
  top: 0; 
  position: absolute; 
  font-Size: 15px; 
  font-Weight: 400px; 
  letter-Spacing: 0.15px;
}
.socialmedia-icons{
  display:flex;
  gap:25px;
  flex-Direction:row;
  color:#000;
  margin-Top: 300px;
  margin-Right:-90px;
  position:absolute;

}

.icons_s-desktop {
  font-size: 25px;
  transition: transform 0.3s ease-out;
  padding-top:0px;
  color:black;
  padding-right: 0px;

}
.icons_s_1-desktop {
  font-size: 35px;
  transition: transform 0.3s ease-out;
 
  color:black;
  padding-right: 10px;

}
.icons_s_2-desktop {
  font-size: 80px;
  transition: transform 0.3s ease-out;
 
  color:black;
  padding-right: 10px;

}
.icons_s_1 {
  font-size: 40px;
  transition: transform 0.3s ease-out;
  padding-bottom:20px;
  color:black;
  padding-right: 10px;
}
.icons_s_2 {
  font-size: 70px;
  transition: transform 0.3s ease-out;
  padding-bottom:50px;
  color:black;
  padding-right: 10px;
}
.icons-mobile{
  font-size: 25px;
  transition: transform 0.3s ease-out;
  padding-right:5px;
  color:black;
  text-align:justify;
}
.icons-mobile1{
  font-size: 50px;
  
  transition: transform 0.3s ease-out;
  padding-right:5px;
  padding-bottom: 30px;
  color:black;
  text-align:justify;
}
.icons-mobile2{
  font-size: 95px;
 
  transition: transform 0.3s ease-out;
  padding-right:5px;
  padding-bottom: 70px;
  color:black;
  text-align:justify;
}
.icons_s-desktop:hover {
  transform: scale(1.2);
  
}
.icons-mobile2:hover {
  transform: scale(1.2);
  
}
.icons-mobile1:hover {
  transform: scale(1.2);
  
}
.icons-mobile:hover {
  transform: scale(1.2);
  
}

.footericons{
  margin-Right:30px;
  color: #000;
  transition: transform 0.3s ease-out;
}
.footericons:hover {
  transform: scale(1.2);
  color: #fff;
}
.fotterbutton{
  margin:10px 0 0 20px;
  background-color: #fff200;
  text-align: center;
}
.facttxt1{
  text-Align:center;
  margin-Top:15px;
  margin-Left:auto;
  margin-Right:auto;  
  color: black; 
  font-Size: 20px;
  font-Family: 'Neuwelt';
  font-Weight: 500;
  animation: slideInDown 2s

}
.facttxt1::after {
  content: '';
  position: absolute;
  width: 36%;
  transform: scaleX(1);
  height: 3px;
  bottom: 500px;
  left: 50px;
  background-color: rgba(255, 255, 255, 0.133);
  visibility: hidden;
  transition: all 0.3s ease-in-out 0s;
}

.facttxt1:hover::after {
  transform: scaleX(1);
  visibility: visible;
}
.facttxt2{
  text-Align:center;
  color: white; 
  font-Size: 30px;
  font-Family: 'Neuwelt';
  font-Weight: 800;
  animation: slideInDown 2s
}
.facttxt2-mobile{
  text-Align:center;
  color: white; 
  font-Size: 22px;
  font-Family: 'Neuwelt';
  font-Weight: 800;
  animation: slideInDown 2s
}
.fact{
  background: #000;
  height: 370px;
  padding: 50px; 
  
}
.fact-mobile{
  background: #000;
  height: 350px;
  padding: 40px;
}
.factcard{
  display:flex; 
  flex-Direction:row;
  animation: slideInDown 2s;

}
.factcard2{
  right:90px; 
  width: 96px; 
  position: relative;  
  margin:auto;
}

.yellowcard{
  width: 290px; 
  height: 120px; 
  background: #FFF200; 
  border-Radius: 10px;
}
.yellowcard-mobile{
  width: 100px; 
  height: 120px; 
  background: #FFF200; 
  border-Radius: 10px;
  margin-left: 70px;
}
.yellowcard-mobile_2{
  width: 120px; 
  height: 120px; 
  background: #FFF200; 
  border-Radius: 10px;
  margin-left: 55px;
  margin-top: 30px;
}
.yellowcard-mobile_3{
  width: 120px; 
  height: 120px; 
  background: #FFF200; 
  border-Radius: 10px;
  margin-left: 50px;
  margin-top: 30px;
}
.yellowcard-mobile_4{
  width: 110px; 
  height: 120px; 
  background: #FFF200; 
  border-Radius: 10px;
  margin-left:65px;
  margin-top: 30px;
}
.yellowcardtext{
  padding-Left:20px;
  height: 130px;
  color: black;
  font-Size: 32px;
  font-Family: 'Neuwelt';
  font-Weight: 700;
  display: flex;
  flex-Direction:column;
  align-Items: center;
  justify-Content: center;
}
.yellowcardtext-mobile{
  padding-Left:0px;
  height: 150px;
  color: black;
  font-Size: 12px;
  font-Family: 'Neuwelt';
  font-Weight: 700;
  display: flex;
  flex-Direction:column;
  align-Items: center;
  justify-Content: center;
}
.yellowcardtext_para{
   padding-left:20px;
   color: black;
   font-size: 18px;
   font-family: 'Neuwelt';
 
   display: flex;
   align-items: center;
   justify-content: center;
}
.yellowcardtext_para-mobile{
  padding-left:0px;
  color: black;
  font-size: 9px;
  font-family: 'Neuwelt';

  display: flex;
  align-items: center;
  justify-content: center;
}
.covecard-mobile{
  display: flex;
  flex-direction: column;
  margin-bottom: 180%;
  
}
.covercardd-desktop{
  width: 28vw; 
  height: 30vh; 
  background: #fff200; 
  border-Radius: 10px;
  position: absolute;
  margin-left: 3%;
  animation: slideInDown 2s;
  
}
.covercardd-mobile{
  margin-left: 3%;
  margin-right: 3%;
  background: #fff200; 
  border-Radius: 10px;
  position: absolute;
  animation: slideInDown 2s;
  justify-content: center;
}
.covercardd-desktop:hover{
  background: #fff;
  
  transition: 0.5s;
}
.covercardd-mobile:hover{
  background: #fff;
  
  transition: 0.5s;
}
.covercard_text{
  width: 80%;
  margin-left: 10%;
  height: 100%;
  color: black;
  font-Family: 'Neuwelt';
  display: flex;
  flex-Direction:column;
  align-Items: center;
  justify-Content: center;
}
.covercard_text-mobile{
  width: 80%;
  margin-left: 10%;

  height: 100%;
  color: black;
  font-Family: 'Neuwelt';
  display: flex;
  flex-Direction:column;
  align-Items: center;
  justify-Content: center;
}
.covercardtext_hm-desktop{
  width: 80%;
  padding-left: 15%;
  height: 100%;
  padding-top: 10%;
  padding-bottom: 10%;
  color: black;
  font-Size: 32px;
  font-Family: 'Neuwelt';
  display: flex;
  flex-Direction:column;
  align-Items: center;
  justify-Content: center;

}
.covercardtext_hm-mobile{
  width: 40%;
  text-align: center;
  margin-left: 30%;
  height: 50%;
  margin-top: 15%;
  color: black;
  font-Size: 22px;
  font-Family: 'Neuwelt';
  display: flex;
  flex-Direction:column;
  align-Items: center;
  justify-Content: center;
}
.covercardtext_ico-desktop{
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom:0px;
}
.covercardtext_ico-mobile{
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom:10px;
  font-size: 20px;
}
.covercardtext_icoo{
  font-size: 50px;
}
.covercardtext_icoo-mobile{
  font-size: 20px;
}
.covercardtext_par1-desktop{
  font-size: 20px;
  display: flex;
  font-weight:Bold;
  text-align: center;
  justify-content: center;
  line-height:1.5;
  font-family:'Neuwelt';
}
.covercardtext_par1-mobile{
  font-size: 12px;
  display: flex;
  padding-bottom: 5px;
  font-weight:Bold;
  text-align: center;
  justify-content: center;
  line-height:1.5;
  font-family:'Neuwelt';
}
.covercardtext_par2-desktop{
  font-size: 13.5px;
  display: flex;
  text-align: justify;
  justify-content: center;
  line-height:1.5;
  font-family:'Neuwelt medium'
}
.covercardtext_par2-mobile{
  font-size: 10px;
  display: flex;
  text-align: justify;
  justify-content: center;
  line-height:1.2;
  width: 35vw;
  font-family:'Neuwelt medium'
}

.busscard{
  width: 10%;
  
  height: 60%;
  color: black;
  font-Family: 'Neuwelt';
  display: flex;
  flex-direction: column;
  flex-Direction:column;
  align-Items: center;
  justify-Content: center;
}
.busscard2{
  width: 100%;
  
  height: -60%;
  color: black;
  font-Family: 'Neuwelt';
  display: flex;
  flex-Direction:column;
  align-Items: center;
  justify-Content: center;
}
.covercardd2-desktop{
  animation: slideInDown 2s;
  width: 28vw; 
  height: 30vh;  
  background: #000; 
  border-Radius: 10px;
  position: absolute;
  margin-left: 37%;
  
}
.covercardd2-mobile{
  animation: slideInDown 2s;
  margin-left: 3%;
  margin-right: 3%;
  background: #000; 
  border-Radius: 10px;
  position: absolute;
  margin-top: 52%;
  justify-content: center;
}
.covercardd2-desktop:hover{
  background: #fff200;
  color: #fff;
  transition: 0.5s;
}
.covercardd2-mobile:hover{
  background: #fff200;
  color: #fff;
  transition: 0.5s;
}
.covercardtext2{
  width: 80%;
  margin-left: 10%;
  height: 100%;
  color: #000;
  font-Size: 32px;
  font-Family: 'Neuwelt';
  display: flex;
  flex-Direction:column;
  align-Items: center;
  justify-Content: center;
}
.covercardtext2-mobile{
  width: 40%;
  margin-left: 40%;
  text-align: center;
  height: 50%;
  
  color: #000;
  font-Size: 20px;
  font-Family: 'Neuwelt';
  display: flex;
  flex-Direction:column;
  align-Items: center;
  justify-Content: center;
}
.covercard_txt2{
  width: 80%;
  margin-left: 10%;
  height: 100%;
  color: #fff;
  font-Size: 32px;
  font-Family: 'Neuwelt';
  display: flex;
  flex-Direction:column;
  align-Items: center;
  justify-Content: center;
}
.covercard_txt2:hover{
  color: #000;
}

.covercardd3{
  animation: slideInDown 2s;
  width: 28vw; 
  height: 30vh; 
  background: #fff200; 
  border-Radius: 10px;
  position: absolute;
  margin-left: 69%;
  
}
.covercardd3-mobile{
  animation: slideInDown 2s;
  background: #fff200; 
  border-Radius: 10px;
  position: absolute;
  margin-top: 108%;
  margin-left: 1%;
  margin-right: 3%;
  justify-content: center;
}
.covercardd3:hover{
  background: #fff;
  
  transition: 0.5s;
}
.covercardd3-mobile:hover{
  background: #fff;
  
  transition: 0.5s;
}
.covercard_text3{
  width: 80%;
  margin-left: 10%;
  height: 100%;
  color: black;
  font-Size: 32px;
  font-Family: 'Neuwelt';
  display: flex;
  flex-Direction:column;
  align-Items: center;
  justify-Content: center;
}
.Ellipse1{
  width: 96px; 
  height: 96px; 
  right: 25px; 
  top:  50px; 
  position: absolute; 
  background: white; 
  border-Radius: 9999px;
}
.Ellipse1:hover{
  background: #fff200;
}
.bounce {
  animation: bounceAnimation 0.5s infinite alternate;
}

@keyframes bounceAnimation {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}
/* .Ellipse1-mobile{
  width: 76px; 
  height: 76px; 
  right: 0px; 
  top:  80px; 
  position: absolute; 
  background:black; 
  border-Radius: 50%;
  font-size: 50px;
} */
.Ellipse1-mobile:hover{
  background: #fff200;
}
.Ellipse_icon{
  position: relative;
  margin-left: 10px;
  top: 80px;
  color: #000;
  font-size:60px;
  margin-top: 10px;
}
.Ellipse_icon-mobile{
  position: relative;
  justify-content: center;
  right: 15px;
  top: 40px;
  color: #000;
  font-size:40px;
 

}
.Ellipse_icon-mobile_1{
  position: relative;
  justify-content: center;
  right: -105px;
  top: 80px;
  color: #000;
  font-size:20px;

}
.Ellipse_icon-mobile_2{
  position: relative;
  justify-content: center;
  right: -100px;
  top: 80px;
  color: #000;
  font-size:20px;

}
.Ellipse_icon-mobile_3{
  position: relative;
  justify-content: center;
  right: -120px;
  top: 80px;
  color: #000;
  font-size:20px;

}
.product_all{
  display:flex;
  width: 100%;
  padding-left: 100px;  
  justify-content: center;

  
}
.product_all-mobile{
  
  width: 100%;  
  justify-content: center;
  padding-top:0%;
}
.round11{
  transition: transform 0.3s ease-out;
  
  
}
.round11:hover {
  transform: scale(1.2);
 
}
.newscrd-desktop{
  width: 374px;
  height: 358px; 
  position: relative;
  
}
.newscrd_2-desktop{
  width: 374px;
  height: 358px; 
  position: relative;
  
}
.newscrd_1-mobile{
  width: 300px;
  margin-bottom: 350px;
  
  position: relative;
}
.newscrd_2-mobile{
  width: 304px;
  margin-bottom: 230px;
  padding-top: 150px;
  position: relative;
}
.newscrd_3-mobile{
  width: 334px;
  margin-bottom: 350px;
  position: relative;
}
.newscrd_4-mobile{
  width: 334px;
  margin-bottom:0px;
  position: relative;
}
.newscrd_5-mobile{
  width: 334px;
  margin-bottom:0px;
  position: relative;
}

.newscrd2-desktop{
  width: 374px;
  
  position: relative;
  
}
.newscrd2-mobile{
  width: 314px;
  height: 358px; 
  position: relative;
}
.news-desktop{
  background: #F9F7F1;
  padding: 50px;
  padding-bottom: 0px;
  display: flex;
  flex-direction: column;
}
.news-mobile{
  background: #F9F7F1;
  padding: 0px 35px ;
 
  padding-bottom: 110px;
  display: flex;
  flex-direction: column;
}
.newrow1-desktop{
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 3%;
  padding-top: 5vh;
   
}
.newrow2-desktop{
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 3%;
  padding-top: 5vh;
   
}
.newrow1-mobile{
  
  
  justify-content: center;
  gap: 3%;
  padding-top: 15vh;
}


.grcard-desktop{
  width: 374px; 
  min-height: 30vh; 
  left: 0px; 
  top: 0px; 
  position: absolute; 
  display: flex;
  background: #fff200; 
  border-radius: 5px;
}
.grcard-desktop:hover{
  background: #fff;
  transition: 0.9s;
}
.grcard-mobile{
 
  background: #fff200; 

  width: 274px; 
  min-height: 35vh; 
  left: 10px; 
  top: -100px; 

  padding-bottom: 35px;
  position: absolute; 
  display: flex;
 
  border-radius: 5px;
}
.grcard-mobile :hover{
  background: #fff;
  transition: 0.9s;
}
.grcar2-desktop{
  width: 374px; 
  min-height: 30vh; 
  left: 0px; 
  top: 0px; 
  position: absolute; 
  display: flex;
  background: #fff; 
  border-radius: 5px;
}
.grcar2-desktop:hover{
  background: #FFF200;
  transition: 0.5s;
}
.grcar2-mobile{
  width: 274px; 
  min-height: 35vh; 
  left: 10px; 
  top: -150px; 
  
  padding-bottom: 35px;
  position: absolute; 
  display: flex;
  background: #fff; 
  border-radius: 5px;
}
.grcar2-mobile:hover{
  background: #FFF200;
  transition: 0.5s;
}
.grcard3-mobile{
  width: 274px; 
  min-height: 35vh; 
  left: 10px; 
  top: -180px; 
  
  position: absolute; 
  display: flex;
  background: #fff200; 
  border-radius: 5px;
}
.grcard3-mobile :hover{
  background: #fff;
  transition: 0.9s;
}
.grcard4-mobile{
  width: 274px; 
  min-height: 35vh; 
  left: 10px; 
  top: -400px; 
  
  position: absolute; 
  display: flex;
  background: #fff; 
  border-radius: 5px;
}
.grcard5-mobile{
  width: 274px; 
  min-height: 35vh; 
  left: 10px; 
  top: -480px; 
  
  position: absolute; 
  display: flex;
  background: #fff200; 
  border-radius: 5px;
}
.grcard6-mobile{
  width: 274px; 
  min-height: 35vh; 
  left: 10px; 
  top: -210px; 
 
  position: absolute; 
  display: flex;
  background: #fff; 
  border-radius: 5px;
}
.grcard4-mobile :hover{
  background: #fff200;
  transition: 0.9s;
}
.grcard5-mobile :hover{
  background: #fff;
  transition: 0.9s;
}
.grcard6-mobile :hover{
  background: #fff200;
  transition: 0.9s;
}
.grcard:hover{
  background: #fff;
  transition: 0.9s;
}
.yshadow{
  width: 374px;
  min-height: 65px;
  left: 0px; 
  top: 0px; 
  position: absolute; 
  border-Radius: 4px; 
  border-Left: 0.50px #FFF200 solid; 
  border-Top: 0.50px #FFF200 solid; 
  border-Right: 0.50px #FFF200 solid; 
  border-Bottom: 0.50px #FFF200 solid;
}
.newscardtxt{
  width: 320px;
  height: 122px; 
  left: 27px; 
  top: 70px; 
  position: absolute;
}
.newscarddate{
  left: 245px;
  top: 0px; 
  position: absolute; 
  color: rgba(194, 191, 191, 0.5); 
  font-Size: 12px; 
  font-Family: 'Neuwelt'; 
  font-Weight: 400; 
  letter-Spacing: 1.50px; 
  
}
.newscardtitel{
  left: 0px;
  top: 23px; 
  position: absolute; 
  color: white; 
  
  
  font-Weight: 500; 
  letter-Spacing: 1.08px; 
}
.newtxt{
  width: 317px; 
  height: 73px; 
  left: 3px; 
  top: 49px; 
  position: absolute; 
  color: white; 

  font-Weight: 300; 
  
}
.readmore-button{
  position: absolute;
  bottom: 5px;
  left: 20%;
  transform: translateX(-50%);
  padding: 10px 20px;
  background-color: #fff; 
  border: none;
  color: black;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  font-family: 'Neuwelt';
  margin: 3px 2px;
  cursor: pointer;
  
  box-shadow: 0 0 5px #fff200;
  transition: box-shadow 0.3s ease;
  transition: background-color 0.9s ease, box-shadow 1s ease;
}
.readmore-button:hover{
    background-color: #000; 
    color: #fff;
    box-shadow: 0 0 10px #000,
    0 0 20px #fff200;
  }

.Aboutus_hm-desktop{
  background: rgb(255, 255, 255);
  padding: 10% 5%  ;
  
}
.Abus_hm-desktop{
  display: flex;
  width: 80vw; 
  justify-content: center;
}
.Aboutus_hm-mobile{
  background: rgb(255, 255, 255);
  
}
.Abus_hm-mobile{

  width: 80vw; 
  justify-content: center;
}
.carr{
  background: rgb(255, 255, 255);
  padding: 5% 5%  ;
  
}
.carr_all{
  display:flex; 
  padding-left: 100px;
  width: 80vw;
  justify-content: center;
}
.carr_all-mobile{
  display:flex;
  flex-direction: column; 
  width: 80vw;
  justify-content: center;
}
.bussi{
  background: rgb(255, 255, 255);
  padding: 0% 3%  ;
  
}
.bussi_all{
  display:flex; 
  width: 70vw; 
  justify-content: center;
}
.bussi_all-mobile{
  display:flex;
  flex-direction: column; 
  width: 80vw;
  justify-content: center;
}
.Aboutustit-desktop{
  padding-top: 28%;
  color: black; 
  font-Size: 32px; 
  font-Family: 'Neuwelt'; 
  font-Weight: 800;
  animation: slideInDown 2s
}
.Aboutustit-mobile{
  padding-top: 15%;
width: 100%;
  color: black; 
  font-Size: 25px; 
  font-Family: 'Neuwelt'; 
  font-Weight: 800;
  animation: slideInDown 2s
}
.carrtit{
  padding-top: 10%;
  color: black; 
  font-Size: 32px; 
  font-Family: 'Neuwelt'; 
  font-Weight: 800;
  animation: slideInDown 2s
}
.carrtit-mobile{
  padding-top: 15%;
  color: black; 
  font-Size: 25px; 
  font-Family: 'Neuwelt'; 
  font-Weight: 800;
  animation: slideInDown 2s
}
.bussitit-desktop{
  padding-bottom: 3%;
  color: black; 
  font-Size: 22px; 
  font-Family: 'Neuwelt'; 
  font-Weight: 400;
  animation: slideInDown 2s
}
.bussitit-mobile{
  padding-top: 12%;
  color: black; 
  width: 150%;
  font-Size: 20px; 
  font-Family: 'Neuwelt'; 
  font-Weight: 800;
  animation: slideInDown 2s
}
.Aboutustit2-desktop{
  padding-top: 0px;
  font-Size: 20px; 
  font-Family: 'Neuwelt'; 
  font-Weight: 200;
  animation: slideInDown 2s
}
.Aboutustit2-mobile{
  padding-top: 0px;
  font-Size: 15px; 
  font-Family: 'Neuwelt'; 
  font-Weight: 200;
  animation: slideInDown 2s
}
.Dro_Sub-desktop{
  font-family:'Neuwelt medium';
  font-size:17px; 
  line-height: 1.5;
  width:125%;
  text-align:justify;
}
.Dro_Sub-mobile{
  font-family:'Neuwelt medium';
  font-size:17px; 
  line-height: 1.5;
  width:150%;
  text-align:justify;
}
.dro_subb-desktop{
  font-family:'Neuwelt medium';
  text-align:justify;
  width: 90%;

  font-Size: 15px; 
  font-Weight: 200;
  animation: slideInDown 2s
}


.dro_subb-mobile{
  font-family:'Neuwelt medium';
  text-align:justify;
  font-size: 15px;
  display: flex;
  
}
.carrtit2{
  padding-top: 0px;
  font-Size: 20px; 
  font-Family: 'Neuwelt'; 
  font-Weight: 200;
  animation: slideInDown 2s
}
.carrtit2-mobile{
  padding-top: 0px;
  width: 150%;
  font-Size: 17px;
  text-align: justify; 
  font-Family: 'Neuwelt'; 
  font-Weight: 200;
  animation: slideInDown 2s
}
.carr_Sub-desktop{
  font-family:'Neuwelt medium';
  font-size:17px; 
  line-height:1.3;
  width:125%;
  text-align:justify;
}
.carr_Sub-mobile{
  font-family:'Neuwelt medium';
  font-size:17px; 
  line-height: 1.5;
  width:150%;
  text-align:justify;
}
.bussi_Sub{
  font-family:'Neuwelt light'; 
  font-size:17px; 
  line-height:1.2;
  width:100%;
}
.bussi_Sub-mobile{
  font-family:'Neuwelt medium';
  font-size:17px; 
  line-height: 1.5;
  width:140%;
  text-align:justify;
}
.bussitit2{
 width: 150%;
  font-Size: 32px; 
  font-Family: 'Neuwelt';
  color: #000; 
  font-Weight: 800;
  animation: slideInDown 2s
}
.bussitit2-mobile{
  padding-top: 15px;
  width: 180%;
  font-Size: 18px;
  text-align: justify; 
  font-Family: 'Neuwelt'; 
  font-Weight: 400;
  animation: slideInDown 2s
}
.bussitit3{
  font-family:'Neuwelt light';
  font-size:17px; 
  line-height:1.2;
  width:150%;
}
.bussitit3-mobile{
  font-family:'Neuwelt light';
  font-size:15px; 
  line-height:1.2;
  width:260%;
  margin-top: 20px;
}
.bussititsub{
  font-family:'Neuwelt light';
  font-size:18px;
  text-align: justify;
}
.bussititsub-mobile{
  font-family:'Neuwelt light';
  text-align: justify;
  font-size:18px;
  
  
}
.bussi_Subtxt{
  font-family:'Neuwelt light';
  font-size:20px;
}
.bussi_Subtxt-mobile{
  font-family:'Neuwelt light';
  font-size:18px;
  
}
.aboutimg-desktop{

  width: 750px; 
  height: 380.81px; 
  border-Radius: 10px;
  padding-right:80px; 
  padding-left:40px;
  animation: slideInLeft 2s;
  margin-top:15%;
}
.aboutimg-desktop:hover{
transform: scale(1.1) ;
transition: 0.5s ease-in-out;
}
.aboutimg-mobile{
  padding-top:40%;
  margin-Right:40px; 
  margin-Left:25px;
  border-Radius: 10px;
}
.carrimg{
  margin-top: 5%;
  width: 590px; 
  height: 610.81px; 
  border-Radius: 10px;
  margin-Right:80px; 
  margin-Left:40px;
  padding-bottom:15%;
  animation: slideInLeft 2s
}
.carrimg:hover{
  transform: scale(1.1) ;
  transition: 0.5s ease-in-out;
}
.carrimg-mobile{
  margin-top: 5%;
  margin-left: 12%;
  border-Radius: 5px;
  
 
  animation: slideInLeft 2s
}
.bussiimg-desktop{
  
  width: 600px; 
  height: 500.81px; 
  border-Radius: 10px;
  padding-bottom:10%; 
  padding-left: 70px;
  margin-right:-30%;
  animation: slideInLeft 2s
}
.bussiimg-desktop:hover{
  transform: scale(1.1) ;
  transition: 0.5s ease-in-out;
}
.bussiimg-mobile{
  margin-top: 2%;
  width: 100vw;
  height: 30vh;
  border-Radius: 10px;
  margin-left:5%; 
  animation: slideInLeft 2s
}
.aboutdesc{
  margin-Top:0px;

  
  margin-Left:auto;
  margin-Right:auto; 
  color: #59606C; 
  font-Size: 15px; 
  font-Family: 'Neuwelt Medium'; 
  font-Weight: 400; 
  line-height: 1.3;
  animation: slideInDown 2s;
  
}
.product_img-desktop{
  margin-top: 0%;
  width: 10vw;
  height: 20vh;
  border-Radius: 10px;
  margin-Right:25%; 
  animation: slideInLeft 2s;
  transition: transform 0.5s ease-in-out;
}
.product_img-desktop:hover {
  transform: scale(1.1) ;
}
.product_img_2-desktop{
  margin-top: 0%;
  width: 10vw;
  height: 20vh;
  border-Radius: 10px;
  margin-Right:11%; 
  animation: slideInLeft 2s;
  transition: transform 0.5s ease-in-out;
}
.product_img_2-desktop:hover{
  transform: scale(1.1) ;
}
.product_img_3-desktop{
  margin-top: 0%;
  width: 10vw;
  height: 20vh;
  border-Radius: 10px;
  margin-Right:25%; 
  animation: slideInLeft 2s;
  transition: transform 0.5s ease-in-out;
}
.product_img_3-desktop:hover{
  transform: scale(1.1) ;
}
.product_img-mobile{
  margin-top: 15%;
  width: 30vw;
  height: 15vh;
  border-Radius: 10px;
 
  animation: slideInLeft 2s;
  transition: transform 0.5s ease-in-out;
}
.product_img-mobile:hover{
  transform: scale(1.1) ;
}
.product_img_2-mobile{
  margin-top: 15%;
  width: 30vw;
  height: 15vh;
  border-Radius: 10px;
  margin-left:5%;
  animation: slideInLeft 2s;
  transition: transform 0.5s ease-in-out;
}
.product_img_2-mobile:hover{
  transform: scale(1.1) ;
}
.Product_hm{
  background: rgb(255, 255, 255);
  padding: 10% 3%;
  
}
.productdesc-desktop{
  padding-top:25px;
width: 50%;
  color: #59606C; 
  font-Size: 15px; 
  font-Family: 'Neuwelt Medium'; 
  font-Weight: 400; 
  line-height: 1.3;
  animation: slideInDown 2s;
  
}
.productdesc-mobile{

  padding-left:30px;
  padding-right:80px;
  padding-bottom: 50px; 
  color: #000; 
  font-Size: 15px; 
  font-Family: 'Neuwelt Medium'; 
  font-Weight: 400; 
  line-height: 1.3;
  animation: slideInDown 2s;
}
.carrdesc{
  margin-Top:10px;

  
  margin-Left:auto;
  margin-Right:auto; 
  color: #59606C; 
  font-Size: 15px; 
  font-Family: 'Neuwelt Medium'; 
  font-Weight: 400; 
  line-height: 1.3;
  animation: slideInDown 2s;
  
}
.carrdesc-mobile{
  padding-left:40px;
  padding-right:80px;
  padding-bottom: 50px; 
  color: #000; 
  font-Size: 15px; 
  font-Family: 'Neuwelt Medium'; 
  font-Weight: 400; 
  line-height: 1.3;
  animation: slideInDown 2s;
}
.bussidesc-desktop{
  width: 70%;
  color: #59606C; 
  padding-left: 500px;
  font-Size: 15px; 
  font-Family: 'Neuwelt Medium'; 
  font-Weight: 400; 
  line-height: 1.3;
  animation: slideInDown 2s;
  
}
.bussidesc-mobile{
  padding-left:25px;
  padding-right:15px;
  padding-bottom: 50px; 
  width: 55%;
  color: #000; 
  font-Family: 'Neuwelt Medium'; 
  font-Weight: 400; 
  line-height: 1.3;
  animation: slideInDown 2s;
}
.bussibranch-mobile{
  width: 100%;
  margin-top: 20px;
}
.bussibranchtitle-mobile{
  width: 100%;
}
.producttit{
  padding-top: 3%;

  
  
  color: black; 
  font-Size: 32px; 
  font-Family: 'Neuwelt'; 
  font-Weight: 400;
  animation: slideInDown 2s
}
.producttit2{
  padding-top: 3%;
  
  font-Size: 20px; 
  font-Family: 'Neuwelt'; 
  font-Weight: 400;
  animation: slideInDown 2s
}
.productsub-desktop{
  padding-top: 3%;
  width: 80%;
  text-align: justify;
  font-Size: 16px; 
  font-Family: 'Neuwelt medium'; 
  font-Weight: 200;
  animation: slideInDown 2s
}
.productsub-mobile{
  padding-top: 3%;
  width: 100%;
  text-align: justify;
  font-Size: 16px; 
  font-Family: 'Neuwelt medium'; 
  font-Weight: 200;
  animation: slideInDown 2s
}
.productsubtxt{
  padding-top: 3%;
  font-Size: 16px; 
  font-Family: 'Neuwelt medium'; 
  font-Weight: 200;
  animation: slideInDown 2s
}
.productsubtxt-mobile{
  padding-top: 3%;
  padding-bottom: 3%;
  font-Size: 16px; 
  font-Family: 'Neuwelt medium'; 
  font-Weight: 200;
  animation: slideInDown 2s
}
.productsubtxt2{
  
  font-Size: 16px; 
  font-Family: 'Neuwelt medium'; 
  font-Weight: 200;
  animation: slideInDown 2s
}
.productsubtxt2-mobile{
  padding-top: 3%;
  padding-bottom: 5%;
  font-Size: 16px; 
  font-Family: 'Neuwelt medium'; 
  font-Weight: 200;
  animation: slideInDown 2s
}
.productsubtxt3{
  
  font-Size: 16px; 
  font-Family: 'Neuwelt medium'; 
  font-Weight: 200;
  animation: slideInDown 2s
}
.product_ico{
  position: relative;
  right: 10px;
 
  top: 0;
  color: #000;
  font-size:20px;
  
}
.product_ico-mobile{
  position: relative;
  right: 5px;
  top: 0px;
  color: #000;
  font-size:15px;
 
}
.footer_sub_txt_3{
  margin-left: 17%;
  font-Size: 16px; 
  font-Family: 'Neuwelt'; 
  font-Weight: 200;
  animation: slideInDown 2s;
  margin-bottom: 20px;
}
.footer_sub_txt_3-mobile{
  margin-left: 30%;
  width: 55%;
  font-Size: 16px; 
  font-Family: 'Neuwelt'; 
  font-Weight: 200;
  animation: slideInDown 2s;
  margin-bottom: 20px;
}
.producttxt-desktop{
  padding-top: 5%;
  display: flex;
  width: 50vw;
  color: black; 
  font-Size: 20px; 
  font-Family: 'Neuwelt'; 
  font-Weight: 400;
  animation: slideInDown 2s
}
.producttxt-mobile{
  padding-top: 5%;
  padding-bottom: 8%;
  display: flex;
  width: 50vw;
  color: black; 
  font-Size: 20px; 
  font-Family: 'Neuwelt'; 
  font-Weight: 400;
  animation: slideInDown 2s
}
.producttxt2-desktop{
  padding-top: 2%;
 display: flex;
  width: 50vw;
  color: black; 
  font-Size: 20px; 
  font-Family: 'Neuwelt'; 
  font-Weight: 400;
  animation: slideInDown 2s
}
.producttxt2-mobile{
  padding-top: 5%;
  padding-bottom: 8%;
  display: flex;
  width: 50vw;
  color: black; 
  font-Size: 20px; 
  font-Family: 'Neuwelt'; 
  font-Weight: 400;
  animation: slideInDown 2s
}
.producttxt3-desktop{
  padding-top: 2%;
 display: flex;
  width: 42vw;
  color: black; 
  font-Size: 20px; 
  font-Family: 'Neuwelt'; 
  font-Weight: 400;
  animation: slideInDown 2s
}
.producttxt3-mobile{
  padding-top: 5%;
  padding-bottom: 8%;
  display: flex;
  width: 50vw;
  color: black; 
  font-Size: 20px; 
  font-Family: 'Neuwelt'; 
  font-Weight: 400;
  animation: slideInDown 2s
}
.rd_txt3{
  padding-top: 5%;
  display: flex;
  width: 50vw;
  color: black; 
  font-Size: 20px; 
  font-Family: 'Neuwelt'; 
  font-Weight: 400;
  animation: slideInDown 2s
}
.producsub1-desktop{
  font-Size: 15px; 
  padding-top: 3%;
  text-align: justify;
 width: 65%;
  font-Family: 'Neuwelt medium'; 
  font-Weight: 200;
  animation: slideInDown 2s
}
.producsub1_2-desktop{
  font-Size: 16px; 
  padding-top: 3%;
  text-align: justify;
 width: 80%;
  font-Family: 'Neuwelt medium'; 
  font-Weight: 200;
  animation: slideInDown 2s
}
.producsub1whatwedo-desktop{
  font-Size: 16px; 
text-align: justify;
  width: 70%;
  font-Family: 'Neuwelt medium'; 
  font-Weight: 200;
  animation: slideInDown 2s
}
.producsub1whatwedo2-desktop{
  font-Size: 16px; 
  text-align: justify;
  width: 100%;
  font-Family: 'Neuwelt medium'; 
  font-Weight: 200;
  animation: slideInDown 2s
}
.producsub1-mobile{
  font-Size: 16px; 
  padding-top: 3%;
  text-align: justify;
  width: 60vw;
  font-Family: 'Neuwelt'; 
  font-Weight: 200;
  animation: slideInDown 2s
}
.producsub1whatwedo-mobile{
  font-Size: 16px; 
  padding-top: 3%;
  text-align: justify;
  width: 60vw;
  font-Family: 'Neuwelt'; 
  font-Weight: 200;
  animation: slideInDown 2s
}
.producico{
  font-size: 50px;
  margin-right:4%;
}
.producic_whatwedo{
  font-size: 2px;
  margin-right:1%;
}
.producico-mobile{
  font-size: 50px;
  margin-right:4%;
}
.producicowhatwedo-mobile{
font-size: small;
  margin-right:4%;
}
.pro_row1{
  display: flex;
  flex-direction: row;
  gap: 60px;
  margin-top: 30px;
}
.product_crd{
  width: 308px;
  animation: slideInDown 2s;
  margin-top: 50px;
  margin-right: auto;
  margin-left: 40px;
  height: 425px; 
  position: relative;
}
.w_card{
  width: 308px; 
  height: 290px; 
  left: 0px; 
  margin-top: 30px;
  top: 90px; 
  position: absolute; 
  background: #010101; 
  border-radius: 10px;
  
}
.g_card{
  width: 308px;
  height: 240px; 
  left: 0px; 
  top: 0px; 
  position: absolute; 
  background: #FFF200; 
  border-radius: 10px;
}
.ProductOne_titel{
  height: 13.39px; 
  left: 24px; 
  top: 260px; 
  position: absolute; 
  color: white; 
  font-Size: 16px; 
  font-Family: 'Neuwelt'; 
  font-Weight: 500; 
  letter-Spacing: 1.08px; 
  
}
.Productone_txt{
  width: 260px; 
  height: 50.89px; 
  left: 24px; 
  top: 285.57px; 
  position: absolute; 
  color: white; 
  font-Size: 13px; 
  font-Family: 'Neuwelt'; 
  font-Weight: 200; 
  letter-Spacing: 1.08px; 
}
.proimg_1{
  width: 182px;
  height: 151px; 
  left: 62px; 
  top: 50px; 
  position: absolute;
}
.proimg_2{
  width: 146px;
  height: 146px; 
  left: 80px; 
  top: 59px; 
  position: absolute;
}
.black_cover-desktop{
  background-color: rgba(43, 43, 3, 0.22); /* Yellow with 50% opacity */
  position: absolute; /* This makes it overlay the image */
  top: 0; 
  display: flex;
  flex-direction: row;
  left: 10px;
  height: 85vh;
  width: 98vw;

}
.black_cover-mobile{
  background-color: rgba(43, 43, 3, 0.22); /* Yellow with 50% opacity */
  position: absolute; /* This makes it overlay the image */
  top: 0; 
  display: flex;
  flex-direction: row;
  left: 0;
  height: 85vh;
  width: 98.4vw;

}


.landititel {
  margin-left: 120px;
  color: white;
  
  font-family: 'Neuwelt';
  font-size: calc(10px + 0.5vw);   /*16px is base size and 0.5vw is scaling factor.*/
}

.landisub {
  margin-left: 120px;
  line-height: 15px;
  letter-spacing: 0.7px;
  width: 40vw;
  color: white;
  font-family: 'Neuwelt Medium';
  font-size: calc(5px + 0.5vw);   /*12px base size and 0.5vw scaling factor.*/
}

@media screen and (min-width: 1024px) {
  .landititel{
    font-size: 50px; 
  }
   .landisub {
    line-height: 25px;
    padding-top: 20px;
    font-size: 18px;  /* Set upper limit of scaling so that text does not become too large*/
  }
}

@media screen and (max-width: 480px) {
  .landititel, .landisub {
    line-height: 3px;
    padding-top: 10px;
    font-size: 10px;  /* Set lower limit of scaling so that text does not become too small */
  }
}

/* .landititle{
  animation-name: fadein;
  animation-duration: 8s;
} */
.landititel {
  animation-name: fadein;
  animation-duration: 8s;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}


.card3 {
  height: 100%;
  padding: 110px 100px;
  margin-right: 0px;
  background-color: rgba(43, 43, 3, 0.22);
  /* Other styles */
}
.my-css-class-desktop {

}
.my-css-class-mobile {

}
.my-css-class1{
  text-align: justify;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  
  line-height:1.8;
  margin-left: 30px;
 
  
  width: 50vw;
  color: white;
  font-family: 'Neuwelt Medium';
  font-size: calc(5px + 0.5vw); 

}
.my-css-class1sub-desktop{
  
  
}
.my-css-class1sub-mobile{

  

}
.first_titel{
  text-align: justify;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 30px;
  width: 50vw;
  color: white;
  font-family: 'Neuwelt medium';

}

.loremText {
  opacity: 10;
  /* Other styles */
}



/* CSS for the container */
.containr_home {
  max-width:100vw;
  height:79vh;
  margin: 0 auto;
  padding: 0 0px;
  box-sizing: border-box;
}

/* CSS for the MaskGroup */
.MaskGroup {
  width: 100%;
  
  background-color: #F9F7F1;
}

/* CSS for the Group170 */
.Group170 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 100%;

  position: absolute;
  top: 0px;
}

/* CSS for the card */
.card {
  max-width: 100%;
  box-sizing: border-box;
  padding: 0 5%;
  width: 90%;
}

/* CSS for the card2 */
.card2 {
  width: 100%;
  max-width: 600px;
  box-sizing: border-box;
  padding: 0 20px;
}

/* Media queries for different screen sizes */
@media only screen and (max-width: 1200px) {
  .Group170 {
    flex-direction: column;
  }
}

@media only screen and (max-width: 992px) {
  .card {
    width: 100%;
    padding: 0 2%;
  }
}

@media only screen and (max-width: 768px) {
  .MaskGroup {
    height: 70%;
  }
}

@media only screen and (max-width: 600px) {
  .MaskGroup {
    height: 90%;
  }
}

.details-button {
  position: absolute;
  bottom: 10px;
  left: 22%;
  transform: translateX(-50%);
  padding: 10px 20px;
  background-color: #fff; 
  border: none;
  color: black;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  font-family: 'Neuwelt';
  margin: 3px 2px;
  cursor: pointer;
  
  box-shadow: 0 0 5px #fff200;
  transition: box-shadow 0.3s ease;
  transition: background-color 0.9s ease, box-shadow 1s ease;
}
.details-button:hover {
  background-color: #000; 
  color: #fff;
  box-shadow: 0 0 10px #000,
  0 0 20px #fff200;
}
@keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}
@keyframes slideInLeft {
  0% {
      transform: translateX(-100%);
      opacity: 0;
  }
  100% {
      transform: translateX(0);
      opacity: 1;
  }
}
@keyframes slideInDown {
  0% {
      transform: translateY(50%);
      opacity: 0;
  }
  100% {
      transform: translateX(0);
      opacity: 1;
  }
}
.moreButtonArea {
  margin-top: 5px;

}
.carrButtonArea {
  margin-top: 10%;
 

}

.moreBtn_home {
  font-size: 15px;
  font-family: 'Neuwelt light';
  border: none;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.3);
  background: #fff200;
  padding-left: 10px;
  padding-right: 10px;
}
.carrBtn-desktop {
  font-size: 20px;
  font-family: 'Neuwelt';
  border: none;
  border-radius: 8px;
  color: #000;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.3);
  background: #fff200;
  padding-left: 10px;
  padding-right: 10px;
  padding: 10px 20px 10px;

}
.carrBtn-mobile{
  font-size: 15px;
  font-family: 'Neuwelt';
  border: none;
  border-radius: 8px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.3);
  background: #fff200;
color: #000;
  padding: 10px 30px 10px;
  margin-left: 5%;
}
.Group200{
  background: #fff200;
  height: 425px; 
  position: 'relative';
}
.Group200-mobile{
  background: #fff200;
  height: 925px; 
  
  display: flex;
  flex-direction: column;
}
.Image2_1-desktop{
  width: 99vw; 
  height:85vh; 
  object-fit: fill;
  background-size:cover;
}
.Image2_1-mobile{
  width: 100%; 
  height:107%; 
  object-fit: cover;
  background-size:cover;
}

@media (max-width: 768px) {
  .my-css-class {
    justify-content: center;
    margin-bottom: 90px;
    font-size: 40px;
    animation: slideInDown 2s;
    color: #fff;
    font-family: 'Neuwelt Medium';
    margin-left: 10%;
  }
  
  .my-css-class1sub {
    font-size: 16px;
  text-align: justify;
  width: 90%;
  color: #fff;
  justify-content: center;
  }
}

@media (min-width: 769px) {
  .my-css-class {
    /* Styles for desktop devices */
    justify-content: center;  
    margin-bottom: 0px;
    font-size: 40px;
    animation: slideInDown 2s;
    color: #fff;
    font-family: 'Neuwelt Medium';
    margin-left: 10%;
    


    
  }
  .transparent-card {
    background: rgba(255, 255, 255, 0.5); /* white background with 50% opacity */
    border-radius: 5px; /* optional, for rounded corners */
    padding: 30px; /* optional, for some spacing */
    padding-right: 150px;
    padding-left: 50px;
    
  }
  .my-css-class1sub {
    /* Styles for desktop devices */
    text-align: justify;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 0px;
    width: 50vw;
    color: #000;
    font-family: 'Neuwelt medium';
    font-size: 18px; 
  }
  
}
