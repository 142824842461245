
.first_col {
  background-color: white;
  height: 620px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: relative;
  width: 550px;
  left: 150px;
  top: 250px;
  border-radius: 20px;
}
.bg-background-desktop{
  background-color:  #FCF5ED;
height: 250vh;
}
.bg-background-mobile{
  background-color:  #FCF5ED;
  height: 300vh;
}
.img-fluid {
  border-radius: 20px;
}

.row-last-desktop {
  width: 100% !important;
  position: relative !important;

  
  height: 350px;
  padding-bottom: 100px !important;
  background-color: #1F1717;


}
.row-last-mobile {
  width: 60% !important;
  position: relative !important;
  left: 350px;
  top: 150px;
  
  height: 550px;
  padding-bottom: 100px !important;
  background-color: #1F1717;
  border-radius: 20px;

}

.btn_s {
  background-color: #fff200;
  border: #c1b688;
  padding: 10px;
  border-radius: 10px;

}

.btn_s:hover {
  background-color: black !important;
  color: white 
}


.form-control {
  height: 52px;
  background: #fff;
  font-size: 14px;
  border-radius: 2px;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 1px solid #c1b688;
}
.form-control[type=text]:focus {
border: 2px solid #555;
}
.col p{
  font-family: 'Neuwelt';
  padding-top: 5px;
}
.col h4{
  font-family: 'Neuwelt';
}
.social-icon {
  margin-left: 10px;
}
.cont_social_media{
    display: flex;
    font-size: 25px;
justify-content: center;
   
}
.cont_social_media a{
  margin: 0 10px;
  border-radius: 50%;
  box-sizing: border-box;
  background: #fff;
  width: 40px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  transition: 0.5s;
  background: rgb(0, 0, 0,0);
  color: #fff200;
  font-size:1em;
  -webkit-box-reflect: below 0px linear-gradient(transparent, #0004 );

}
.cont_social_media a:hover{
padding-left: 10px;
  color: #050801;
  transform: ease-in-out;
  transition: 0.5s;


}

@media only screen and (max-width: 600px) {
  .first_col {
      position: initial;
      left: 0 !important;
  }

  .sec_col {
      order: 1 !important;
  }

  .row-last-desktop {
      left: 0 !important;
      width: 100% !important;
      height: 500px !important;
      top: 0 !important;
      
  }
  .row-last-mobile {
    left: 0 !important;
    width: 100% !important;
    height: 1050px !important;
    top: 0 !important;
}
}