.outlined-btn {
  border: 2px solid black;
  background-color: white;
  color: black;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
  border-radius: 7%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
}
.outlined-btn:hover {
  background-color: #ffff00;
  color: #000;
}
.fotter-sub{
  background: linear-gradient(to left, #000, #000000);
  width: 100%;
  height: 212px;
  left: 0px;
  top: 35px; 
  position: relative; 
  padding: 180px 60px;
  display: flex;
  justify-Content: center;
  align-Items: center;
  border-radius: 20px;
  
}
.footer-right{
  height: 30px;
  position: relative;
  display: flex;
  justify-Content:center;
  align-Content:center;
}
.footerRight-txt {
  top: 10px;
  position: relative;
  color: #6C727F; 
  font-Size: 12px;
  font-Family: 'Lexend';
  font-Weight: 400;
  
}
.fotter-icon{
  padding-right: 1000px;
  margin:0 auto;
  height: 55px;
}
.footerSub-txt1{
  height: 200px;
  position: absolute;
}

.footerSub-txt11{
 text-align: center;
  right: 0px;
  left: -350px;
  position: absolute;
  color: white;
  font-Size: 15px; 
  font-Weight: 700 ;
  width: 160px;

}
.footerSub-para{
  padding-top: 50px;
  right: 0px;
  left: -350px;
  position: absolute;
  color: white;
  font-Size: 15px; 
  width: 380px;
}



.footerSub-txt2{
  height: 200px;
  position: absolute;
}

.footerSub-txt22{
 text-align: center;
  right: 0px;
  left: 50px;
  position: absolute;
  color: white;
  font-Size: 15px; 
  font-Weight: 700 ;
  width: 160px;

}
.footerSub-para2{
  padding-top: 50px;
  right: 0px;
  left: 50px;
  position: absolute;
  color: white;
  font-Size: 15px; 
  width: 450px;
}




.footer_para3:hover{
  color: #FFFF00;
  cursor: pointer;
}
.footer_para:hover{
  color: #FFFF00;
  cursor: pointer;
}
.footer_para2:hover{
  color: #FFFF00;
  cursor: pointer;
}
.footer_para4:hover{
  color: #FFFF00;
  cursor: pointer;
}
.footer_para5:hover{
  color: #FFFF00;
  cursor: pointer;
}
.footer_para6:hover{
  color: #FFFF00;
  cursor: pointer;
}
.footer_para7:hover{
  color: #FFFF00;
  cursor: pointer;
}
.footer_para8:hover{
  color: #FFFF00;
  cursor: pointer;
}
.footer_para9:hover{
  color: #FFFF00;
  cursor: pointer;
}
.footer_para10:hover{
  color: #FFFF00;
  cursor: pointer;
}
.footer_para11:hover{
  color: #FFFF00;
  cursor: pointer;
}
.footerSub-txt3{
  height: 200px;
  position: absolute;
}

.footerSub-txt33{
 text-align: center;
 padding-left: 100px;
  right: 0px;
  left: 360px;
  position: absolute;
  color: white;
  font-Size: 15px; 
  font-Weight: 700 ;
  width: 50vh;

}
.footerSub-para3{
  padding-top: 50px;
  padding-left: 150px;
  right: 0px;
  left: 360px;
  position: absolute;
  color: white;
  font-Size: 15px; 
  width: 50vh;
}







.socialmedia {
  width: 400px;
  top: 250px;
  left: 60px;
  height: 100px;
  position: absolute;
  color: #fff;
}
.SubscribeUs{
  
  left: 0;
  top: 0; 
  position: absolute; 
  font-Size: 15px; 
  font-Weight: 400px; 
  letter-Spacing: 0.15px;
}
.socialmedia-icons{
  display:flex;
  gap:25px;
  flex-Direction:row;
  color:#000;
  margin-Top: 300px;
  margin-Right:-90px;
  position:absolute;

}
.icons{
  font-Size:30px;
}
.icons {
  font-size: 30px;
  color:#FFFF00;
  transition: transform 0.3s ease-out;
}

.icons:hover {
  transform: scale(1.2);
  color: #fff;
}
.footericons{
  margin-Right:10px;
  color: #FFFF00;
  transition: transform 0.3s ease-out;
}
.footericons:hover {
  transform: scale(1.2);
  color: #fff;
}
.fotterbutton{
  margin:10px 0 0 20px;
  background-color: #ffff00;
  text-align: center;
}
.facttxt1{
  text-Align:center;
  margin-Top:15px;
  margin-Left:auto;
  margin-Right:auto;  
  color: black; 
  font-Size: 20px;
  font-Family: Lexend; 
  font-Weight: 500;

}
.facttxt1::after {
  content: '';
  position: absolute;
  width: 36%;
  transform: scaleX(0);
  height: 3px;
  bottom: -1603px;
  left: 430px;
  background-color: #000;
  visibility: hidden;
  transition: all 0.3s ease-in-out 0s;
}

.facttxt1:hover::after {
  transform: scaleX(1);
  visibility: visible;
}
.Drogagrouptxt{
  text-Align:center;
  color: black; 
  padding-bottom: 50px;
  font-Size: 30px;
  font-Weight: 700;
}
.Drogagroup{
  background: rgba(243, 244, 246, 0.62);
  height: 350px;
  padding: 50px; 
}
.Drogapartener{
  background: rgba(243, 244, 246, 0.62);
  height: 300px;
  padding: 50px; 
  
}
.Drogagroupbrand{
  display:flex; 
  flex-Direction:row;
  justify-content: center;
}
.Drogapar{
  display:flex; 
  flex-Direction:row;
  gap: 70px;
}
.factcard2{
  right:90px; 
  width: 96px; 
  position: relative;  
  margin:auto;
}



.round11{
  transition: transform 0.3s ease-out;
}
.round11:hover {
  transform: scale(1.2);
}

.productdetailcard-desktop{

  margin: auto;
  width: 50vw;
  height: 50vh;
  position: relative;
  min-height: 55vh; 
  
  
  justify-content: center;
  justify-self: center;
}
.productdetailcard-desktop:hover{
  transform: scale(1.1);
  transition: 1.2s ease-in-out;
  }
.news_detail_card-desktop{
  width: 60vw;
  padding-top:50px;
}
.productdetailcard-mobile{
  
    margin: auto;
    width: 50vw;
    height: 30vh;
    position: relative;
    min-height:40vh; 
    
    
    justify-content: center;
    justify-self: center;
}
.productdetailcardimg-desktop{
object-fit: cover;
  width: 30vw;
  height: 50vh;
  position:absolute;
  margin:0% 20%;
}

.productdetailcardimg-mobile{
  
    width: 90vw;
    height: 30vh;
    position:absolute;
    margin:0% 5%;
}
/* .productdetailcardimg:hover{
  transition: 0.9s;
  transform: scale(1.1);
} */
.productdetailtxt-desktop{
  margin: auto;
  width: 50vw;
  max-height: 120vh;
  position: relative;
}
.productdetailtxt-mobile{
right: 20px;

  width: 90vw;
  max-height: 100vh;
  position: relative;
}
.news_detail_card_all-mobile{
  padding-bottom:480px;
}
.proDescription::after {
  content: '';
  position: absolute;
  width: 105%;
  transform: scaleX(1);
  background-color: #F6F60F;
  visibility: visible;
  transition: all 0.3s ease-in-out 0s;
  height: 3.5px; 
  left: 0px; 
  top: 20px;
  
  
}

.proDescription:hover::after {
  transform: scaleX(0.5);
  visibility: visible;
}
.productdetailcardback-desktop{
  margin: auto;
  width: 50vw;
  height: 50vh;
  left: 0px; 
  top: 0px; 
  position: absolute; 
  background: #fff; 
  border-Radius: 10px;
}
.productdetailcardback:hover{
  background: gray;
  transition: 0.9s;
  transform: scale(1.1);
}
.productdetailcardback-mobile{

  width: 100vw;
  height: 50vh;
  right: 0px; 
  left: -50px;
  top: 0px; 
  position: absolute; 
  background: #fff; 
  border-Radius: 10px;
}
.ProCategory{
  left: 0px;
  top: 130px; 
  position: absolute; 
  color: black; 
  font-Size: 24px; 
  font-Family: 'Lexend'; 
  font-Weight: 500; 
}
.ProCategorysub{
  left: 2px;
  top: 171px; 
  position: absolute; 
  color: black; 
  font-Size: 12px; 
  font-Family: 'Lexend'; 
  font-Weight: 400; 
  letter-Spacing: 0.40px; 
}
.proDescription{
  left: 0px; 
  top: 50px; 
  position: absolute; 
  color: black; 
  font-Size: 16px; 
  font-Family: 'Lexend'; 
  font-Weight: 400; 
  letter-Spacing: 0.50px; 
}
.proDetails{
  left: 120px;
  top: 50px; 
  position: absolute; 
  color: black; 
  font-Size: 16px;
  font-Family: 'Lexend'; 
  font-Weight: 400; 
  letter-Spacing: 0.50px; 

}
.productdetailtxt2-desktop{
  width: 50vw;
  left: 0px; 
  padding-bottom: 20px;
  text-align: justify;
  color: black; 
  font-Size: 18px; 
  font-Family: 'Neuwelt light'; 
  font-Weight: 400; 
  line-height: 30px;
}
.productdetailtxt2-mobile{
  width: 85vw;
  left: 0px; 
  padding-bottom: 10px;
  text-align: justify;
  color: black; 
  font-Size: 15px; 
  font-Family: 'Neuwelt light'; 
  font-Weight: 400; 
  line-height: 18px;
}
.news_search-desktop{
  display:flex;
  flex-direction:column;
  padding-top: 50px;
}
.news_search-mobile{
  display:flex;
  flex-direction:column;
  
}
.product_detailtitel-desktop{

  color: black; 
  font-Size: 30px; 
  font-Family: 'Neuwelt'; 
  font-Weight: 600; 
  margin-bottom: 10px;
}
.product_detailtitel-mobile{
  color: black; 
  font-Size: 19px; 
  font-Family: 'Neuwelt'; 
  font-Weight: 600; 
  margin-bottom: 10px;
}
.Productdetails-desktop{
  background: #F9F7F1;
  padding: 50px 0px 400px 0px;
  font-Family: 'Neuwelt'; 
}
.Productdetails-mobile{
  background: #F9F7F1;
  padding: 50px 150px 0px 50px;
}
.newss_deta-desktop{
  display:flex; 
  margin-left:2%;
}

.Productdetailstitel{
  text-Align:center;
  color: black; 
  font-Size: 32px; 
  font-Family: 'Lexend'; 
  font-Weight: 500;
}
.RelatedProductstitel{
  margin-left: 40px;
  color: black; 
  font-Size: 32px; 
  font-Family: 'Lexend'; 
  font-Weight: 500;
  
}
.RelatedProduct{
  background: rgb(211, 211, 211);
  padding: 50px;
}
.cata_ul-desktop{
  list-style:none;
}
.cata_ul-desktop li{
  border-bottom:1px solid #e5e6e7;
  
  padding-bottom:15px;


}
.cata_ul-desktop li a{
  font-size: 15px;
  font-family: 'Neuwelt medium';
}
.cata_ul-desktop li a:hover{
  color: #fff200;
  cursor: pointer;
}
.cata_ul-mobile{
  list-style:none;
}
.cata_ul-mobile li{
  border-bottom:1px solid #e5e6e7;
  margin-bottom:10px;
  padding-bottom:10px;
}
.cata_ul-mobile li a{
  font-size: 15px;
  font-family: 'Neuwelt medium';
}
.cata_ul-mobile li a:hover{
  color: #fff200;
  cursor: pointer;
}
.side_container-desktop{
  padding-top:50px;

  background:#fff;
  min-height:15vh;
  
  padding:30px;
  width:30vw
}
.side_container-mobile{
  padding-top:90px;
  margin-left:-5%; 
  background:#fff;
  min-height:15vh;

  padding:25px;
  width:80vw
}
.side_container_2-desktop{
  margin-top:0px;
 
  background:#fff;
  min-height:15vh;
  margin-bottom:10px;
  padding:30px;
  width:30vw
}
.side_container_2-mobile{
  margin-top:10px;
  margin-left:-5%; 
  background:#fff;
  min-height:15vh;

  padding:25px;
  width:80vw
}
.side_container_3-desktop{
  margin-top:50px;

  background:#fff;
  min-height:15vh;
  margin-bottom:10px;
  padding:30px;
  width:30vw
}
.side_container_3-mobile{
  margin-top:10px;
  margin-left:-5%; 
  background:#fff;
  min-height:15vh;
  margin-bottom:30px;
  padding:25px;
  width:80vw
}
.searchForm{
  padding:20px 150px 0px 30px;
  
}
.comment_section-desktop{
  margin-top:50px;
  margin-left:13%; 
  background:#fff;
  min-height:15vh;
  margin-bottom:10px;
  padding:30px;
  width:70vw
}
.comment_section-mobile{
  margin-top:10px;
  margin-left:-5%; 
  background:#fff;
  min-height:15vh;
  margin-bottom:30px;
  padding:25px;
  width:80vw
}
.com_btn-desktop{
  border:none;
  color:#000 ;
  font-weight:700;
  height:50px;
  border-radius: 10px;
  margin-top:15px;
  padding:5px 30px;
  text-transform:uppercase;
  transition:background-color 0.3s ease;
}
.com_btn-desktop:hover {
  background-color: #000;
  color: #fff;
  border: 1px solid;
}
.com_btn-desktop:active {
  animation-name: bubbly;
  animation-duration: 0.3s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
}
.com_btn-mobile{
  border:none;
  color:#000 ;
  font-weight:700;
  height:40px;
  margin-top:15px;
  font-size: 12px;
  padding:5px 20px;
  text-transform:uppercase;
  transition:background-color 0.3s ease;
}
.com_btn-mobile:hover {
  background-color: #fff;
  border: 1px solid;
}
.com_btn-mobile:active {
  animation-name: bubbly;
  animation-duration: 0.3s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
}
.com_texarea-desktop{
 margin-left:-10px;
  margin-right:-10px;
}
.com_texarea-mobile{
  margin-left:-10px;
  margin-right:-10px;
}
.com_texarea_sub-desktop{
  border:1px solid #eee;
  height:120px;
  padding:5px 20px;
  width:80%;
  resize:'none';
}
.com_texarea_sub-mobile{
  border:1px solid #eee;
  height:120px;
  padding:5px 20px;
  width:95%;
  resize:'none';
}
.com_email-desktop{
  border:1px solid #eee;
  height:30px;
  padding:5px 20px; 
  width:150%
}
.com_email-mobile{
  border:1px solid #eee;
  height:30px;
  padding:5px 20px; 
  width:180%
}
.com_name-desktop{
  border:1px solid #eee;
  height:30px;
  padding:5px 20px; 
  width:180%;
}
.com_name-mobile{
  border:1px solid #eee;
  height:30px;
  padding:5px 20px; 
  width:180%;
}
.com_name_email-desktop{

  display:flex; 
  padding-bottom:15px;
}
.com_name_email-mobile{
  margin-left:-10px;
  display:flex;
  flex-direction: column; 
  margin-bottom:15px;
}
.comment_name_email-desktop{
  padding-right:20%;
}
.comment_name_email-mobile{
  padding-right:45%;
  padding-bottom: 20px;
}
.popular_post_content-desktop{
  flex-basis:calc(100% - 100px);
  flex-grow:1;
  flex-shrink:0;
  max-width:calc(100%-90px);
  padding-left:10px;
  display: flex;
  justify-content: space-between;
}
.popular_post_content-mobile{
padding-top: 20px;
}
.new_detail_blog-desktop{
  display:flex;
  width: 100%;

  margin-bottom:15px;
  padding-bottom:15px; 
}
.new_detail_blog-mobile{
 display: flex;
 flex-direction: column;
 padding-right: 50px;
}
.new_detail_image{
  width:100%
}
.new_detail_image_sub-desktop{
  width:95px;
  height:80px;
  
}
/* .popular_post_content h5 a{
  font-size: 10px;
  font-family: 'Neuwelt';
  line-height: 1.5;
} */
.popular_post_content h5 a:hover{
  color: #fff200;
  cursor: pointer;
}
.popular_post_content span{
  
  font-size:13px;
  font-family: 'Neuwelt light';

}
.popular_post_content-mobile h5 a{
  font-size: 13px;
  font-family: 'Neuwelt';
  line-height: 1.5;
}
.popular_post_content-mobile h5 a:hover{
  color: #fff200;
  cursor: pointer;
}
.popular_post_content-mobile span{
  
  font-size:12px;
  font-family: 'Neuwelt light';

}

.Group_9-desktop{
  height: 305px; 
  position: relative;
  justify-content:center;
}
.Group_9-mobile{
  height: 305px; 
  position: relative;
  justify-content:center;
}
.pop_post{
  margin-left:30px
}
.com_all-desktop{
  padding-left:150px
}
.com_all-mobile{
  padding-left:30px
}
.com_all_txt-desktop{
  padding-left:-1px;
  padding-right:-10px;
  padding-bottom: 20px;
  font-weight: 500;
  font-family: 'Neuwelt';
}
.com_all_txt-mobile{
  margin-left:-1px;
  margin-right:-10px;
  padding-bottom: 20px;
  font-weight: 500;
  font-family: 'Neuwelt';
}
.product_detail_div-desktop{
  background: #F9F7F1;
  padding: 30px;
  font-Family: 'Neuwelt'; 
}

.product_detail_div-mobile{
  background: #F9F7F1;
  padding: 30px;
  font-Family: 'Neuwelt'; 
}
.yellow_product_detail_cover{
  background:linear-gradient(to left, rgba(0, 0, 0, 0), rgba(255, 242, 0));
  position: absolute;
  top: 0; 
  display: flex;
  flex-direction: row;
  left: 0;
  height: 61%;
  width: 100%;
  animation: fadeIn 5s
}
.Product_detail_titel-desktop{
  text-Align:center;
  color: black; 
  font-Size: 45px; 
  font-Family: 'Neuwelt'; 
  font-Weight: 500;
  padding: 80px;
}
.Product_detail_titel-mobile{
  text-Align:center;
  color: black; 
  font-Size: 35px; 
  font-Family: 'Neuwelt'; 
  font-Weight: 500;
  padding: 120px 80px 0px 80px;
}