.outlined-btn {
  border: 2px solid black;
  background-color: white;
  color: black;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
  border-radius: 7%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
}
.outlined-btn:hover {
  background-color: #ffff00;
  color: #000;
}
.fotter-sub{
  background: linear-gradient(to left, #000, #000000);
  width: 100%;
  height: 212px;
  left: 0px;
  top: 35px; 
  position: relative; 
  padding: 180px 60px;
  display: flex;
  justify-Content: center;
  align-Items: center;
  border-radius: 20px;
  
}
.newsfooter-right{
  height: 30px;
  position: relative;
  background:#000;
  display: flex;
  justify-Content:center;
  align-Content:center;
}
.newsfooterRight-txt {
  top: 10px;
  position: relative;
  color: #6C727F; 
  font-Size: 12px;
  font-Family: 'Lexend';
  font-Weight: 400;
  
}
.newsfotter-icon{
 padding-right: 80%;
  color: #000;
  margin:0 auto;
  height: 55px;
}
.newsfooterSub-txt1{
  height: 200px;
  position: absolute;
}

.newsfooterSub-txt11{
  text-align: center;
  right: 0px;
  left: -320px;
  position: absolute;
  color: #000;
  font-Size: 30px; 
  font-Weight: 700 ;
  width: 160px;
}
.newsfooterSub-para{
  padding-top: 50px;
  right: 0px;
  left: -380px;
  position: absolute;
  color: #000;
  font-Size: 15px; 
  width: 380px;
}
.newsfootersubtxt3{
  margin-left: 17%;
  font-Size: 16px; 
  font-Family: 'Neuwelt'; 
  font-Weight: 200;
  animation: slideInDown 2s
}


.newsfooterSub-txt2{
  height: 200px;
  position: absolute;
}

.newsfooterSub-txt22{
  text-align: center;
  right: 0px;
  left: 110px;
  position: absolute;
  color: #000;
  font-Size: 30px; 
  font-Weight: 700 ;
  width: 160px;
}
.newsfooterSub-para2{
  padding-top: 50px;
  right: 0px;
  left: 15px;
  position: absolute;
  color: #000;
  font-Size: 15px; 
  width: 450px;
}




.footer_para3:hover{
  color: #FFFF00;
  cursor: pointer;
}
.footer_para:hover{
  color: #FFFF00;
  cursor: pointer;
}
.footer_para2:hover{
  color: #FFFF00;
  cursor: pointer;
}
.footer_para4:hover{
  color: #FFFF00;
  cursor: pointer;
}
.footer_para5:hover{
  color: #FFFF00;
  cursor: pointer;
}
.footer_para6:hover{
  color: #FFFF00;
  cursor: pointer;
}
.footer_para7:hover{
  color: #FFFF00;
  cursor: pointer;
}
.footer_para8:hover{
  color: #FFFF00;
  cursor: pointer;
}
.footer_para9:hover{
  color: #FFFF00;
  cursor: pointer;
}
.footer_para10:hover{
  color: #FFFF00;
  cursor: pointer;
}
.footer_para11:hover{
  color: #FFFF00;
  cursor: pointer;
}
.newsfooterSub-txt3{
  height: 200px;
  position: absolute;
}

.newsfooterSub-txt33{
  text-align: center;
  padding-left: 100px;
   right: 0px;
   left: 350px;
   position: absolute;
   color: #000;
   font-Size: 30px; 
   font-Weight: 700 ;
   width: 50vh;

}
.newsfooterSub-para3{
  padding-top: 50px;
  padding-left: 190px;
  right: 0px;
  left: 250px;
  position: absolute;
  color: #000;
  font-Size: 15px; 
  width: 70vh;
}







.socialmedia {
  width: 400px;
  top: 250px;
  left: 60px;
  height: 100px;
  position: absolute;
  color: #fff;
}
.SubscribeUs{
  
  left: 0;
  top: 0; 
  position: absolute; 
  font-Size: 15px; 
  font-Weight: 400px; 
  letter-Spacing: 0.15px;
}
.socialmedia-icons{
  display:flex;
  gap:25px;
  flex-Direction:row;
  color:#000;
  margin-Top: 300px;
  margin-Right:-90px;
  position:absolute;

}
.icons{
  font-Size:30px;
}
.icons {
  font-size: 30px;
  color:#FFFF00;
  transition: transform 0.3s ease-out;
}

.icons:hover {
  transform: scale(1.2);
  color: #fff;
}
.footericons{
  margin-Right:10px;
  color: #FFFF00;
  transition: transform 0.3s ease-out;
}
.footericons:hover {
  transform: scale(1.2);
  color: #fff;
}
.fotterbutton{
  margin:10px 0 0 20px;
  background-color: #ffff00;
  text-align: center;
}
.facttxt1{
  text-Align:center;
  margin-Top:15px;
  margin-Left:auto;
  margin-Right:auto;  
  color: black; 
  font-Size: 20px;
  font-Family: Lexend; 
  font-Weight: 500;

}
.facttxt1::after {
  content: '';
  position: absolute;
  width: 36%;
  transform: scaleX(0);
  height: 3px;
  bottom: -1603px;
  left: 430px;
  background-color: #000;
  visibility: hidden;
  transition: all 0.3s ease-in-out 0s;
}





.round11{
  transition: transform 0.3s ease-out;
}
.round11:hover {
  transform: scale(1.2);
}

.new_moreButtonArea-desktop {
  margin-top: 5px;

}
.new_moreButtonArea-mobile{
  margin-top: 5px;
  margin-left: 0%;
}
.new_moreBtn-desktop {
  font-size: 15px;
  font-family: 'Neuwelt light';
  border: none;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.3);
  background: #fff200;
  padding-left: 10px;
  padding-right: 10px;
}
.new_moreBtn-mobile{
  font-size: 15px;
  font-family: 'Neuwelt light';
  border: none;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.3);
  background: #fff200;
  padding-left: 10px;
  padding-right: 10px;
}
.News-desktop{
  background: #F9F7F1;
  padding: 50px;
  font-Family: 'Neuwelt'; 
}
.News-mobile{
  background: #F9F7F1;
  padding:4% 4% 158% 4%;
  

  display: flex;
  flex-direction: column;
}
.Newstitel{
  text-Align:center;
  color: black; 
  font-Size: 32px; 
  font-Family: 'Neuwelt'; 
  font-Weight: 500
}
.productcard{
  display: flex;
  flex-direction: column;
  
}
.newrow_1-desktop{
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 70px;
  margin-bottom: 10%;
}
.newrow_1-mobile{
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 70px;
  margin-bottom: 10%;
}
.newrow_2-desktop{
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 70px;
  margin-bottom: 20%;
  margin-top: 20%;
}
.newrow_2-mobile{
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 70px;
  margin-bottom: 20%;
  margin-top: 20%;
}
.news_crd{
  width: 374px;
  height: 558px; 
  position: relative;
}
.wh_card{
  width: 374px;
  height: 354px; 
  left: 0px; 
  top: 254px; 
  position: absolute; 
  background: white;
  border-Top-Left-Radius: 5px; 
  border-Top-Right-Radius: 5px;
}
.grrcard{
  width: 374px; 
  height: 154px; 
  left: 0px; 
  top: 0px; 
  position: absolute; 
  background: #EEEEEE; 
  border-Top-Left-Radius: 5px; 
  border-Top-Right-Radius: 5px;
}
.yshadow{
  width: 374px;
  height: 308px;
  left: 0px; 
  top: 0px; 
  position: absolute; 
  border-Radius: 4px; 
  border-Left: 0.50px #FFFF00 solid; 
  border-Top: 0.50px #FFFF00 solid; 
  border-Right: 0.50px #FFFF00 solid; 
  border-Bottom: 0.50px #FFFF00 solid;
}
.ProductOnetitel{
  height: 13.39px; 
  left: 24px; 
  top: 309.82px; 
  position: absolute; 
  color: black; 
  font-Size: 15px; 
  font-Family: 'Lexend'; 
  font-Weight: 900; 
  letter-Spacing: 1.08px; 
  
}
.newscardtxt{
  width: 320px;
  height: 122px; 
  left: 27px; 
  top: 168px; 
  position: absolute;
}
.newscarddate{
  left: 245px;
  top: 1px; 
  position: absolute; 
  color: rgba(0, 0, 0, 0.50); 
  font-Size: 10px; 
  font-Family: 'Lexend'; 
  font-Weight: 400; 
  letter-Spacing: 1.50px; 
  
}
.newscardtitel{
  left: 0px;
  top: 23px; 
  position: absolute; 
  color: black; 
  font-Size: 12px; 
  font-Family: 'Lexend'; 
  font-Weight: 500; 
  letter-Spacing: 1.08px; 
}
.proimg2{
  width: 146px;
  height: 146px; 
  left: 80px; 
  top: 79px; 
  position: absolute;
}
.newtxt{
  width: 317px; 
  height: 73px; 
  left: 3px; 
  top: 49px; 
  position: absolute; 
  color: black; 
  font-Size: 15px; 
  font-Family: 'Lexend'; 
  font-Weight: 300; 
  
}
.yellow_newscover-desktop{
  background:linear-gradient(to left, rgba(0, 0, 0, 0), rgba(255, 242, 0));
  position: absolute; /* This makes it overlay the image */
  top: 0; 
  
  display: flex;
  flex-direction: row;
  left: 0;
  height: 80vh;
  width: 100%;
  animation: fadeIn 5s
}
.yellow_newscover-mobile{
  background:linear-gradient(to left, rgba(0, 0, 0, 0), rgba(255, 242, 0));
  position: absolute; /* This makes it overlay the image */
  top: 0; 
  display: flex;
  flex-direction: row;
  left: 0;
  height: 75%;
  width: 100%;
  animation: fadeIn 5s
}
.Group1_99{
  height: 305px; 
  position: relative;
  justify-content:center;
}
.Group1_99-mobile{
  height: 305px; 
  position: relative;
  justify-content:center;
}
.covercardtext_news-desktop{
  width: 80%;
  margin-left: 10%;
  height: 100%;
  margin-top: 5%;
  margin-bottom: 5%;
  color: #000;
  font-Size: 32px;
  font-Family: 'Neuwelt';
  display: flex;
  flex-Direction:column;


}
.covercardtext_news-mobile{
  width: 80%;
  margin-left: 10%;
  height: 100%;
  margin-top: 5%;
  margin-bottom: 5%;
  color: #000;
  font-Size: 20px;
  font-Family: 'Neuwelt';
  display: flex;
  flex-Direction:column;
}
.covercardtext_tit-desktop{
  font-size: 20px;
  font-weight:Bold;
  line-height:1.5;
  font-family:'Neuwelt ExtraLight';
}
.covercardtext_tit-mobile{
  font-size: 18px;
  font-weight:Bold;
  line-height:1.5;
  font-family:'Neuwelt ExtraLight';
}
.covercardtext_tit2-desktop{
  font-size: 15px;
  display:flex;
  text-align:justify;
  line-height:1.5;
  font-family:'Neuwelt ExtraLight';
}
.covercardtext_tit2-mobile{
  font-size: 13px;
  display:flex;
  text-align:justify;
  line-height:1.5;
  font-family:'Neuwelt ExtraLight';
}
.newscrd_new-desktop{
  width: 374px;
  height: 358px; 
  position: relative;
  
}
.newscrd_new-mobile{
  width: 345px;
  height: 358px; 
  position: relative;
  
}

.grcard_new-desktop{
  width: 374px; 
  min-height: 40vh; 
  left: 0px; 
  top: 0px; 
  position: absolute; 
 
  background: #fff; 
  border-radius: 5px;
}
.grcard_new-desktop:hover{
  background: gray;
  transition: 0.9s;
  
}
.grcard_new-mobile{
  width: 300px; 
  min-height: 30vh; 
  left: 20px; 
  top: 0px; 
  position: absolute; 
 
  background: #fff; 
  border-radius: 5px;
}
.grcard_new-mobile:hover{
  background: gray;
  transition: 0.9s;
  
}
.grcard_new_2{
  width: 374px; 
  min-height: 40vh; 
  left: 0px; 
  top: 0px; 
  position: absolute; 
 
  background: #fff; 
  border-radius: 5px;
}
.grcard_new_2-mobile{
  width: 300px; 
  min-height: 30vh; 
  left: 20px; 
  top: 100px; 
  position: absolute; 
 
  background: #fff; 
  border-radius: 5px;
}
.grcard_new_2-mobile:hover{
  background: gray;
  transition: 0.9s;
  
}
.grcard_new_3{
  width: 374px; 
  min-height: 40vh; 
  left: 0px; 
  top: 0px; 
  position: absolute; 
 
  background: #fff; 
  border-radius: 5px;
}
.grcard_new_3-mobile{
  width: 300px; 
  min-height: 30vh; 
  left: 20px; 
  top: 200px; 
  position: absolute; 
 
  background: #fff; 
  border-radius: 5px;
}
.grcard_new_3-mobile:hover{
  background: gray;
  transition: 0.9s;
  
}
.grcard_new_4-mobile{
  width: 300px; 
  min-height: 30vh; 
  left: 20px; 
  top: 330px; 
  position: absolute; 
 
  background: #fff; 
  border-radius: 5px;
}
.grcard_new_4-mobile:hover{
  background: gray;
  transition: 0.9s;
  
}
.grcard_new_5-mobile{
  width: 300px; 
  min-height: 30vh; 
  left: 20px; 
  top: 370px; 
  position: absolute; 
 
  background: #fff; 
  border-radius: 5px;
}
.grcard_new_5-mobile:hover{
  background: gray;
  transition: 0.9s;
  
}
.grcard_new_6-mobile{
  width: 300px; 
  min-height: 30vh; 
  left: 20px; 
  top: 420px; 
  position: absolute; 
 
  background: #fff; 
  border-radius: 5px;
}
.grcard_new_6-mobile:hover{
  background: gray;
  transition: 0.9s;
  
}
.newsdiv{
  background: #F9F7F1;
  padding:50px;
  font-Family: 'Neuwelt'; 
}
.news_image{
  height:100%;
  width: 100%;
  object-fit: cover;
}
.news_image:hover{
  transition: 0.9s;
  transform: scale(1.1);
}
.News_titel{
  text-Align:center;
  color: black; 
  font-Size: 55px; 
  font-Family: 'Neuwelt'; 
  font-Weight: 500;
  padding: 150px;
}
.News_titel-mobile{
  text-Align:center;
  color: black; 
  font-Size: 55px; 
  font-Family: 'Neuwelt'; 
  font-Weight: 500;
  padding: 120px 50px 0px 50px;
}
.newsfootersub{
  width: 100%;
  height: 212px;
  left: 0px;
  top: 35px; 
  position: relative; 
  padding: 180px 60px;
  display: flex;
  justify-Content: center;
  align-Items: center;
  border-radius: 20px;
}