.outlined-btn {
    border: 2px solid black;
    background-color: white;
    color: black;
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
    text-decoration: none;
    border-radius: 7%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
  }
  .outlined-btn:hover {
    background-color: #ffff00;
    color: #000;
  }
  .fotter-sub{
    background: linear-gradient(to left, #000, #000000);
    width: 100%;
    height: 212px;
    left: 0px;
    top: 35px; 
    position: relative; 
    padding: 180px 60px;
    display: flex;
    justify-Content: center;
    align-Items: center;
    border-radius: 20px;
    
  }
  .footer-right{
    height: 30px;
    position: relative;
    display: flex;
    justify-Content:center;
    align-Content:center;
  }
  .footerRight-txt {
    top: 10px;
    position: relative;
    color: #6C727F; 
    font-Size: 12px;
    font-Family: 'Lexend';
    font-Weight: 400;
    
  }
  .fotter-icon{
    padding-right: 1000px;
    margin:0 auto;
    height: 55px;
  }
  .footerSub-txt1{
    height: 200px;
    position: absolute;
  }
  
  .footerSub-txt11{
   text-align: center;
    right: 0px;
    left: -350px;
    position: absolute;
    color: white;
    font-Size: 15px; 
    font-Weight: 700 ;
    width: 160px;
  
  }
  .footerSub-para{
    padding-top: 50px;
    right: 0px;
    left: -350px;
    position: absolute;
    color: white;
    font-Size: 15px; 
    width: 380px;
  }
  
  
  
  .footerSub-txt2{
    height: 200px;
    position: absolute;
  }
  
  .footerSub-txt22{
   text-align: center;
    right: 0px;
    left: 50px;
    position: absolute;
    color: white;
    font-Size: 15px; 
    font-Weight: 700 ;
    width: 160px;
  
  }
  .footerSub-para2{
    padding-top: 50px;
    right: 0px;
    left: 50px;
    position: absolute;
    color: white;
    font-Size: 15px; 
    width: 450px;
  }
  
  
  
  
  .footer_para3:hover{
    color: #FFFF00;
    cursor: pointer;
  }
  .footer_para:hover{
    color: #FFFF00;
    cursor: pointer;
  }
  .footer_para2:hover{
    color: #FFFF00;
    cursor: pointer;
  }
  .footer_para4:hover{
    color: #FFFF00;
    cursor: pointer;
  }
  .footer_para5:hover{
    color: #FFFF00;
    cursor: pointer;
  }
  .footer_para6:hover{
    color: #FFFF00;
    cursor: pointer;
  }
  .footer_para7:hover{
    color: #FFFF00;
    cursor: pointer;
  }
  .footer_para8:hover{
    color: #FFFF00;
    cursor: pointer;
  }
  .footer_para9:hover{
    color: #FFFF00;
    cursor: pointer;
  }
  .footer_para10:hover{
    color: #FFFF00;
    cursor: pointer;
  }
  .footer_para11:hover{
    color: #FFFF00;
    cursor: pointer;
  }
  .footerSub-txt3{
    height: 200px;
    position: absolute;
  }
  
  .footerSub-txt33{
   text-align: center;
   padding-left: 100px;
    right: 0px;
    left: 360px;
    position: absolute;
    color: white;
    font-Size: 15px; 
    font-Weight: 700 ;
    width: 50vh;
  
  }
  .footerSub-para3{
    padding-top: 50px;
    padding-left: 150px;
    right: 0px;
    left: 360px;
    position: absolute;
    color: white;
    font-Size: 15px; 
    width: 50vh;
  }
  
  
  
  
  
  
  
  .socialmedia {
    width: 400px;
    top: 250px;
    left: 60px;
    height: 100px;
    position: absolute;
    color: #fff;
  }
  .SubscribeUs{
    
    left: 0;
    top: 0; 
    position: absolute; 
    font-Size: 15px; 
    font-Weight: 400px; 
    letter-Spacing: 0.15px;
  }
  .socialmedia-icons{
    display:flex;
    gap:25px;
    flex-Direction:row;
    color:#000;
    margin-Top: 300px;
    margin-Right:-90px;
    position:absolute;
  
  }
  .icons{
    font-Size:30px;
  }
  .icons {
    font-size: 30px;
    color:#FFFF00;
    transition: transform 0.3s ease-out;
  }
  
  .icons:hover {
    transform: scale(1.2);
    color: #fff;
  }
  .footericons{
    margin-Right:10px;
    color: #FFFF00;
    transition: transform 0.3s ease-out;
  }
  .footericons:hover {
    transform: scale(1.2);
    color: #fff;
  }
  .fotterbutton{
    margin:10px 0 0 20px;
    background-color: #ffff00;
    text-align: center;
  }
  .facttxt1{
    text-Align:center;
    margin-Top:15px;
    margin-Left:auto;
    margin-Right:auto;  
    color: black; 
    font-Size: 20px;
    font-Family: Lexend; 
    font-Weight: 500;
  
  }
  .RD_detail_div-desktop{
    background: #F9F7F1;
    padding: 20px;
    font-Family: 'Neuwelt'; 
  }
  .RD_detail_div-mobile{
    background: #F9F7F1;
    padding: 30px;
    font-Family: 'Neuwelt'; 
  }
  .yellow_RD_detail_cover_rd-desktop{
    background:linear-gradient(to left, rgba(0, 0, 0, 0), rgba(255, 242, 0));
    position: absolute; /* This makes it overlay the image */
    top: 0; 
    display: flex;
    flex-direction: row;
    left: 0;
    height: 90%;
    width: 100%;
    animation: fadeIn 5s
  }
 .yellow_RD_detail_cover-mobile{
  background:linear-gradient(to left, rgba(0, 0, 0, 0), rgba(255, 242, 0));
  position: absolute;
  top: 0; 
  display: flex;
  flex-direction: row;
  left: 0;
  height: 101.5%;
  width: 100%;
  animation: fadeIn 5s
 }
 .Group199_rd-mobile{
  height: 230px; 
  position: relative;
  justify-content:center;
 }
 .Group199_rd-desktop{
  height: 300px; 
  position: relative;
  justify-content:center;
 }
  .RD_detail_titel-desktop{
    text-Align:center;
    color: black; 
    font-Size: 55px; 
    font-Family: 'Neuwelt'; 
    font-Weight: 500;
    padding: 150px;
  }
  .RD_detail_titel-mobile{
    padding-top: 10%;
    color: black  ; 
    text-align: center;
  
    font-Size: 35px; 
    font-Family: 'Neuwelt'; 
    font-Weight: 800;
    animation: slideInDown 2s
  }
  .facttxt1::after {
    content: '';
    position: absolute;
    width: 36%;
    transform: scaleX(0);
    height: 3px;
    bottom: -1603px;
    left: 430px;
    background-color: #000;
    visibility: hidden;
    transition: all 0.3s ease-in-out 0s;
  }
  
  .facttxt1:hover::after {
    transform: scaleX(1);
    visibility: visible;
  }
  .Drogagrouptxt{
    text-Align:center;
    color: black; 
    padding-bottom: 50px;
    font-Size: 30px;
    font-Weight: 700;
  }
  .Drogagroup{
    background: rgba(243, 244, 246, 0.62);
    height: 350px;
    padding: 50px; 
  }
  .Drogapartener{
    background: rgba(243, 244, 246, 0.62);
    height: 300px;
    padding: 50px; 
    
  }
  .Drogagroupbrand{
    display:flex; 
    flex-Direction:row;
    justify-content: center;
  }
  .Drogapar{
    display:flex; 
    flex-Direction:row;
    gap: 70px;
  }
  .factcard2{
    right:90px; 
    width: 96px; 
    position: relative;  
    margin:auto;
  }
  
  
  
  .round11{
    transition: transform 0.3s ease-out;
  }
  .round11:hover {
    transform: scale(1.2);
  }
  
  .newdetailcard{
  
    width: 464px;
    margin:auto; 
    height: 383px; 
    position: relative;
    display: flex;
    justify-content: center;
  }
  
  .RD_detail_txt-desktop{

    width: 70vw;
    min-height: 30vh;
    position: relative;
  }
  .RD_detail_txt-mobile{
    width: 70vw;
    min-height: 70vh;
    position: relative;
  }
  
  
  
  
  
  
  .ProCategory{
    left: 0px;
    top: 130px; 
    position: absolute; 
    color: black; 
    font-Size: 24px; 
    font-Family: 'Lexend'; 
    font-Weight: 500; 
  }
  .ProCategorysub{
    left: 2px;
    top: 171px; 
    position: absolute; 
    color: black; 
    font-Size: 12px; 
    font-Family: 'Lexend'; 
    font-Weight: 400; 
    letter-Spacing: 0.40px; 
  }
  
  .proDetails_0{
    left: 30%;
    top: 50px; 
    position: absolute; 
    color: black; 
    font-Size: 16px;
    font-Family: 'Neuwelt medium'; 
    font-Weight: 400; 
    letter-Spacing: 0.50px; 
  }
  .proDetails_1{
    left: 0px;
    top: 50px; 
    position: absolute; 
    color: black; 
    font-Size: 16px;
    font-Family: 'Neuwelt medium'; 
    font-Weight: 400; 
    letter-Spacing: 0.50px; 
  }
  .RD_detailtxt_2-desktop{
    width: 85vw;
    left: 0px; 
    top: 96px; 
    position: absolute; 
    color: black; 
    font-size: 19px;
    font-Family: 'Neuwelt Light'; 
    font-Weight: 400; 
    letter-Spacing: 1px; 
    line-height: 1.5;
    text-align: justify;
    
  }
  .RD_detailtxt_2-mobile{
    width: 90vw;
    left: 0px; 
    top: 80px; 
    position: absolute; 
    color: black; 
    font-size: 17px;
    font-Family: 'Neuwelt Light'; 
    font-Weight: 400; 
    letter-Spacing: 1px; 
    line-height: 1.5;
    text-align: justify;
  }
  .RD_detailtitel_1-desktop{
    margin-left:0%;
   
    color: black; 
    font-Size: 30px; 
    font-family: 'Neuwelt'; 
    font-Weight: 700; 
    letter-Spacing: 0.25px; 
  }
  .RD_detailtitel_1-mobile{
    color: black; 
    font-Size: 30px; 
    font-family: 'Neuwelt'; 
    font-Weight: 700; 
    letter-Spacing: 0.25px; 
  }
  .RD_details-desktop{
    background: '#F9F7F1';
    padding: 50px;
    margin-left: 50px;
  }
  .RD_details-mobile{
    background: '#F9F7F1';
    padding: 10px;
    margin-left: 10px;
  }
  .usually_asked{
    background: rgba(0, 0, 0, 0);
    padding: 50px;
  }
  .newdetails_titel{
    text-Align:center;
    color: black; 
    font-Size: 32px; 
    font-family: 'Neuwelt'; 
    font-Weight: 500;
    
  }
  .Relatednewss_titel{
    margin-left: 40px;
    color: black; 
    font-Size: 32px; 
    font-family: 'Neuwelt'; 
    font-Weight: 500;
    
  }
  .news_detail_crd_new{
    width: 374px;
    height: 358px; 
    position: relative;
    
  }
  .gr_detail_card_new{
    width: 374px; 
    min-height: 40vh; 
    left: 0px; 
    top: 0px; 
    position: absolute; 
   
    background: #fff; 
    border-radius: 5px;
  }
  .gr_detail_card_new:hover{
    background: gray;
    transition: 0.9s;
    
  }
  .news_detail_image{
    height:100%;
    width: 100%;
    object-fit: cover;
  }
  .news_detail_image:hover{
    transition: 0.9s;
    transform: scale(1.1);
  }
  .covercardtext_news_detail{
    width: 80%;
    margin-left: 10%;
    height: 100%;
    margin-top: 5%;
    margin-bottom: 5%;
    color: #fff;
    font-Size: 32px;
    font-Family: 'Neuwelt';
    display: flex;
    flex-Direction:column;
  
  
  }
  .covercardtext_news_detail{
    width: 80%;
    margin-left: 10%;
    height: 100%;
    margin-top: 5%;
    margin-bottom: 5%;
    color: #000;
    font-Size: 32px;
    font-Family: 'Neuwelt';
    display: flex;
    flex-Direction:column;
  
  
  }
  
  .new_moreButton_Area {
    margin-top: 5px;
  
  }
  button {
    background-color: #fff200;
    
    transition: transform 0.3s;
  }
  
  button.active {
    transform: scaleX(1.2);
  }
  .bubbly-button {
    background-color: #fff200;
    color: #000;
    border: none;
    padding: 5px 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .bubbly-button:hover {
    background-color: #fff;
  }
  
  .bubbly-button:active {
    animation-name: bubbly;
    animation-duration: 0.3s;
    animation-timing-function: linear;
    animation-iteration-count: 1;
  }
  
  @keyframes bubbly {
    0% {
      transform: scale(1);
      box-shadow: 0 0 0 0 #fff200;
    }
    70% {
      transform: scale(1.3);
      box-shadow: 0 0 0 10px rgba(0, 140, 186, 0);
    }
    100% {
      transform: scale(1);
      box-shadow: 0 0 0 0 rgba(0, 140, 186, 0);
    }
  }
  
  .news_detail_footerRight_txt {
    top: 10px;
    position: relative;
    color: #6C727F; 
    font-Size: 12px;
    font-Family: 'Lexend';
    font-Weight: 400;
    
  }
  .news_detail_footer_right{
    height: 30px;
    position: relative;
    background:#000;
    display: flex;
    justify-Content:center;
    align-Content:center;
  }
  .news_detail_footersubtxt3{
    margin-left: 17%;
    font-Size: 16px; 
    font-Family: 'Neuwelt'; 
    font-Weight: 200;
    animation: slideInDown 2s;
    margin-bottom: 20px;
  }
  .news_detail_footerSub_para_3{
    padding-top: 75px;
    padding-left: 190px;
    right: 0px;
    left: 250px;
    position: absolute;
    color: #000;
    font-Size: 15px; 
    width: 70vh;
  }
  .news_detail_footerSub_txt33{
    text-align: center;
    padding-left: 100px;
     right: 0px;
     left: 350px;
     position: absolute;
     color: #000;
     font-Size: 30px; 
     font-Weight: 700 ;
     width: 50vh;
  
  }
  .news_detail_footerSub_txt3{
    height: 200px;
    position: absolute;
  }
  .news_detail_footerSub_para_2{
    padding-top: 75px;
    right: 0px;
    left: 15px;
    position: absolute;
    color: #000;
    font-Size: 15px; 
    width: 450px;
  }
  .news_detail_footerSub_txt22{
    text-align: center;
    right: 0px;
    left: 110px;
    position: absolute;
    color: #000;
    font-Size: 30px; 
    font-Weight: 700 ;
    width: 160px;
  }
  .news_detail_footerSub_txt2{
    height: 200px;
    position: absolute;
  }
  .news_detail_footerSub_para_1{
    padding-top: 75px;
    right: 0px;
    left: -380px;
    position: absolute;
    color: #000;
    font-Size: 15px; 
    width: 380px;
  }
  .news_detail_footerSub_txt11{
    text-align: center;
    right: 0px;
    left: -320px;
    position: absolute;
    color: #000;
    font-Size: 30px; 
    font-Weight: 700 ;
    width: 160px;
  }
  .news_detail_footerSub_txt1{
    height: 200px;
    position: absolute;
  }
  .news_detail_fotter_icon{
    padding-right: 80%;
     color: #000;
     margin:0 auto;
     height: 55px;
   }
   .news_detail_footersub{
    width: 100%;
    height: 212px;
    left: 0px;
    top: 35px; 
    position: relative; 
    padding: 180px 60px;
    display: flex;
    justify-Content: center;
    align-Items: center;
    border-radius: 20px;
  }
  .rd_cov-desktop{
    background: #F9F7F1;
    padding: 50px;
    font-Family: 'Neuwelt'; 
  }
  .rd_desc-desktop{
    margin-Left:10%;
    margin-Right:auto; 
    color: #59606C; 
    font-Size: 15px; 
    font-Family: 'Neuwelt Medium'; 
    font-Weight: 400; 
    line-height: 1.3;
    animation: slideInDown 2s;
    
  }
  .rd_subtit_p-mobile{
    font-family:'Neuwelt light';
    font-size:20px;
    line-height:1.5;
    text-align: justify;
    width: 120%;
  }
  .rd_subtit_p-desktop{
    font-family:'Neuwelt light';
    font-size:20px;
    text-align: justify;
    line-height:1.5;
    width: 100%;
  }
  .rd_desc-mobile{
    margin-Left:10%;
    margin-Right:auto;
    color: #59606C; 
    font-Size: 15px; 
    font-Family: 'Neuwelt Medium'; 
    font-Weight: 400; 
    line-height: 1.3;
    animation: slideInDown 2s;
  }
  .rd_itit{
    padding-bottom: 3%;
    color: black; 
    font-Size: 22px; 
    font-Family: 'Neuwelt'; 
    font-Weight: 400;
    animation: slideInDown 2s
  }
  .rd_tit2-desktop{
 
    font-Size: 32px; 
    font-Family: 'Neuwelt light';
    color: #000; 
    
    animation: slideInDown 2s
  }
  .rd_tit2-mobile{
     
    font-Size: 20px; 
    font-Family: 'Neuwelt light';
    color: #000; 
    width: 220px;
    animation: slideInDown 2s
  }
  .rd_sub1{
    font-Size: 16px; 
    padding-top: 3%;
   text-align: justify;
    font-Family: 'Neuwelt'; 
    font-Weight: 200;
    animation: slideInDown 2s
  }
  .rd_img-desktop{
  
    width: 100vw; 
    height: 400.81px; 
    border-Radius: 10px;
    padding-bottom:15%; 
    margin-left: 40px;
  
   
    animation: slideInLeft 2s
  }
  .rd_img-mobile{
    width: 265px; 
    height: 200px; 
    border-Radius: 10px;
    padding-bottom:0%; 
    padding-top: 0px;
    padding-left: 20px;
   
    animation: slideInLeft 2s
  }
  .rd_txt3-desktop{
    width:100%;
    display: flex;
    flex-direction: column;
  }
  .rd_txt3-mobile{
    width:130%;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
  }
  .rd_txt_2-desktop{
    width:100%;
    margin-top: 50px;
    display: flex;
    flex-direction: row;
  }
  .rd_txt_2-mobile{
    width:100%;
    margin-top: 50px;
    display: flex;
    flex-direction: row;
  }
  .rd_all-desktop{
    display:flex ;
  
    width: 80vw; 
    justify-content: center; 
  }
  .rd_all-mobile{
    display:flex ;
    flex-direction: column;
    padding-left: 20px;
    justify-content: center; 
  }
  .rd_cov-mobile{
    display:flex ;
    position: relative;
    width: 68vw; 
    justify-content: center; 
  }
  .rd_subtit-desktop{
    font-family:'Neuwelt light';
    font-size:17px; 
    line-height:1.2;
    width:80%;
  }
  .rd_subtit-mobile{
    font-family:'Neuwelt light';
    font-size:15px; 
    text-align: justify;
    line-height:1.2;
    width:100%;
  }
  .rd_tiall-mobile{
    margin-left: 0px;
    margin-top: 50px;
    width: 250px;
    
  }
  .rd_style-desktop{
    animation: slideInDown 2s;
    transition:'background .3s, border .3s, border-radius .3s, box-shadow .3s, '; 
    position:relative;
    z-index:1;
    margin-left:90%;
  }
  .rd_style-mobile{
    animation: slideInDown 2s;
    
    position:relative;
    z-index: 1;
    margin-left:20%;
  }
  .rd_style_2-desktop{
    text-align:left;
    margin-bottom:0px;
  }
  .rd_style_2-mobile{
    text-align:left;
    margin-bottom:0px;
  }
  .rd_style_txt-desktop{
    margin-top:80px;
    font-size:125px;
    font-weight:700;
    line-height:10px;
    color:transparent;
    -webkit-text-stroke:1px rgb(136 147 185 / 0.4);
    transform:rotate(-90deg);
    transform-origin:left top 0;
  }
  .rd_style_txt-mobile{
    display: none;
  }
  .rd_tit_subb-desktop{
    font-family:'Neuwelt';
  }
  .rd_bullet-desktop{
    display:flex;
  }
  .rd_bullet-mobile{
    display:none;
   
  }
  .rd_txt_tit_2-desktop{
    font-family:'Neuwelt';
    width:60%;
    text-align:justify;
  }
  .rd_txt_tit_2-mobile{
    font-family:'Neuwelt';
    width:60%;
    text-align:justify;
  }
  .RD_accordion-desktop{
    padding:0px 100px;
  }
  .RD_accordion-mobile{
    padding:0px;
  }