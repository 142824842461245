

 
  .navitem{
    text-Decoration: none ;

  }
  .navitem:hover{
    color: white;
  }

  .navico {
    position: relative;
    width: 38%;
    height: 0%;
    padding-top: 30px;
    padding-bottom: 10px;
}

@media screen and (max-width: 600px) {
    .navico {
        width: 30%;
        height: 25%;
        margin-bottom: 15px;
       
    }
}
.dptitle{
  padding-Top:55px;
  font-Family:'Neuwelt';
  font-size: 18px;
}

@media screen and (max-width: 600px) {
  .dptitle {
      width: 50%;
      height: 25%;
      padding-top:35px;
      font-size: 10px;
      
  }
}
.dpsub{
  font-Family:'Neuwelt';
  font-Weight:200;
  font-Size:16px;
}
@media screen and (max-width: 600px) {
  .dpsub {
      width: 80%;
      height: 10%;
      font-size: 5px;
      
  }
}
/* CSS for the dropdown menu */
.dropdown {
  position: relative;
  display: inline-block;
  
}

/* .dropdown-content {
  position: absolute;
  display: none;
  top: 100%;
  left: 0;
  width: 100vw;
  height: 50vh;
  background-color: white;
  z-index: 999;
  overflow: auto;
} */

.dropdown-content {
  display: none;
  position: fixed;
  top: 110px;
  left: 0;
  width: 100%;
  height: 50vh;
  font-family: 'Neuwelt Medium';
  background-color: white;
  z-index: 1;
}

.dropdown-content2 {
  display: none;
  position: fixed;
  top: 110px;
  left: 0;
  width: 100%;
  height: 45vh;
  background-color: white;
  font-family: 'Neuwelt Medium';
  z-index: 1;
}

.dropdown:hover .dropdown-content2 {
  display: block;
  background-color: #fff200;
}
.dropdown:hover .dropdown-content {
  display: block;
  background-color: #fff200;
  
}
.com_img{
  width: 28%; 
  height: 28%;
  border-radius:8px;
  margin: 8px 0 10px;
}
.com_img:hover{
  transform: scale(1.1); 
  transition: transform 0.3s ease-in-out;
}
.press_image_1{
  width: 450%; 
  height:250%;
  border-radius:8px;
}
.press_image_1:hover{
  transform: scale(1.1); 
  transition: transform 0.3s ease-in-out;
}
.press_image_2{
  width: 35%; 
  height: 20%;

  border-radius:8px;
  margin: 8px 0 10px;

}
.press_image_2:hover{
  transform: scale(1.1); 
  transition: transform 0.3s ease-in-out;
}
.Menu{
  justify-content: flex-start;
}
@media (min-Width:768px) {

  .Menu{
    display:none;
  }
  
}
.home_social_media{
  display: flex;
  font-size: 20px;
  justify-content: center;
 
}
.home_social_media a{
margin: 0 10px;
border-radius: 50%;
box-sizing: border-box;
background: #fff;
width: 30px;
height: 20px;
display: flex;
justify-content: center;
align-items: center;
text-decoration: none;
transition: 0.5s;
background: rgb(0, 0, 0,0);
color: #fff200;
font-size:1em;
-webkit-box-reflect: below 0px linear-gradient(transparent, #0004 );

}
.home_social_media a:hover{
padding-left: 10px;
color: #050801;

transform: ease-in-out;
transition: 0.5s;


}
.home_social_media-mobile{
  display: flex;
  font-size: 20px;
  padding-top: 10px;
justify-content: center;
}
.home_social_media-mobile a{
  margin: 0 10px;
  border-radius: 50%;
  box-sizing: border-box;
  background: #fff;
  width: 30px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  transition: 0.5s;
  background: rgb(0, 0, 0,0);
  color: #fff200;
  font-size:1em;
  -webkit-box-reflect: below 0px linear-gradient(transparent, #0004 );
}
.home_social_media-mobile a:hover{
  padding-left: 10px;
  color: #050801;
  
  transform: ease-in-out;
  transition: 0.5s;
}
.signin_options{
  padding-top: 48px;
  position: relative;
}
.signin_options-mobile{
  position: absolute;
  padding-top: 50px;
  padding-left: 290px;
}
.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
 }
 
 @keyframes spin {
   0% { transform: rotate(0deg); }
   100% { transform: rotate(360deg); }
 }
 