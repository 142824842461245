.outlined-btn {
  border: 2px solid black;
  background-color: white;
  color: black;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
  border-radius: 7%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
}
.outlined-btn:hover {
  background-color: #ffff00;
  color: #000;
}
.fotter-sub{
  background: linear-gradient(to left, #000, #000000);
  width: 100%;
  height: 212px;
  left: 0px;
  top: 35px; 
  position: relative; 
  padding: 180px 60px;
  display: flex;
  justify-Content: center;
  align-Items: center;
  border-radius: 20px;
  
}
.footer-right{
  height: 30px;
  position: relative;
  display: flex;
  justify-Content:center;
  align-Content:center;
}
.footerRight-txt {
  top: 10px;
  position: relative;
  color: #6C727F; 
  font-Size: 12px;
  font-Family: 'Lexend';
  font-Weight: 400;
  
}
.fotter-icon{
  padding-right: 1000px;
  margin:0 auto;
  height: 55px;
}
.footerSub-txt1{
  height: 200px;
  position: absolute;
}

.footerSub-txt11{
 text-align: center;
  right: 0px;
  left: -350px;
  position: absolute;
  color: white;
  font-Size: 15px; 
  font-Weight: 700 ;
  width: 160px;

}
.footerSub-para{
  padding-top: 50px;
  right: 0px;
  left: -350px;
  position: absolute;
  color: white;
  font-Size: 15px; 
  width: 380px;
}



.footerSub-txt2{
  height: 200px;
  position: absolute;
}

.footerSub-txt22{
 text-align: center;
  right: 0px;
  left: 50px;
  position: absolute;
  color: white;
  font-Size: 15px; 
  font-Weight: 700 ;
  width: 160px;

}
.footerSub-para2{
  padding-top: 50px;
  right: 0px;
  left: 50px;
  position: absolute;
  color: white;
  font-Size: 15px; 
  width: 450px;
}




.footer_para3:hover{
  color: #FFFF00;
  cursor: pointer;
}
.footer_para:hover{
  color: #FFFF00;
  cursor: pointer;
}
.footer_para2:hover{
  color: #FFFF00;
  cursor: pointer;
}
.footer_para4:hover{
  color: #FFFF00;
  cursor: pointer;
}
.footer_para5:hover{
  color: #FFFF00;
  cursor: pointer;
}
.footer_para6:hover{
  color: #FFFF00;
  cursor: pointer;
}
.footer_para7:hover{
  color: #FFFF00;
  cursor: pointer;
}
.footer_para8:hover{
  color: #FFFF00;
  cursor: pointer;
}
.footer_para9:hover{
  color: #FFFF00;
  cursor: pointer;
}
.footer_para10:hover{
  color: #FFFF00;
  cursor: pointer;
}
.footer_para11:hover{
  color: #FFFF00;
  cursor: pointer;
}
.footerSub-txt3{
  height: 200px;
  position: absolute;
}

.footerSub-txt33{
 text-align: center;
 padding-left: 100px;
  right: 0px;
  left: 360px;
  position: absolute;
  color: white;
  font-Size: 15px; 
  font-Weight: 700 ;
  width: 50vh;

}
.footerSub-para3{
  padding-top: 50px;
  padding-left: 150px;
  right: 0px;
  left: 360px;
  position: absolute;
  color: white;
  font-Size: 15px; 
  width: 50vh;
}







.socialmedia {
  width: 400px;
  top: 250px;
  left: 60px;
  height: 100px;
  position: absolute;
  color: #fff;
}
.SubscribeUs{
  
  left: 0;
  top: 0; 
  position: absolute; 
  font-Size: 15px; 
  font-Weight: 400px; 
  letter-Spacing: 0.15px;
}
.socialmedia-icons{
  display:flex;
  gap:25px;
  flex-Direction:row;
  color:#000;
  margin-Top: 300px;
  margin-Right:-90px;
  position:absolute;

}
.icons{
  font-Size:30px;
}
.icons {
  font-size: 30px;
  color:#FFFF00;
  transition: transform 0.3s ease-out;
}

.icons:hover {
  transform: scale(1.2);
  color: #fff;
}
.footericons{
  margin-Right:10px;
  color: #FFFF00;
  transition: transform 0.3s ease-out;
}
.footericons:hover {
  transform: scale(1.2);
  color: #fff;
}
.fotterbutton{
  margin:10px 0 0 20px;
  background-color: #ffff00;
  text-align: center;
}
.facttxt1{
  text-Align:center;
  margin-Top:15px;
  margin-Left:auto;
  margin-Right:auto;  
  color: black; 
  font-Size: 20px;
  font-Family: Lexend; 
  font-Weight: 500;

}
.facttxt1::after {
  content: '';
  position: absolute;
  width: 36%;
  transform: scaleX(0);
  height: 3px;
  bottom: -1603px;
  left: 430px;
  background-color: #000;
  visibility: hidden;
  transition: all 0.3s ease-in-out 0s;
}





.round11{
  transition: transform 0.3s ease-out;
}
.round11:hover {
  transform: scale(1.2);
}



.Product{
  background: #F9F7F1;
  padding: 50px;
  display: flex;
  flex-direction: column;
}
.Producttitel{
  text-Align:center;
  color: black; 
  font-Size: 32px; 
  font-Family: 'Lexend'; 
  font-Weight: 500
}
.productcard{
  display: flex;
  flex-direction: column;
  
}
.prorow1{
  display: flex;
  flex-direction: row;
  gap: 80px;
  margin-top: 30px;
}
.productcrd{
  width: 308px;
  height: 425px; 
  position: relative;
}
.wcard{
  width: 308px; 
  height: 425px; 
  left: 0px; 
  top: 0; 
  position: absolute; 
  background: white; 
  border-Radius: 8px;
}
.gcard{
  width: 261px;
  height: 262.50px; 
  left: 23px; 
  top: 21px; 
  position: absolute; 
  background: #D9D9D9; 
  border-Radius: 15px;
}
.ProductOnetitel{
  height: 13.39px; 
  left: 24px; 
  top: 309.82px; 
  position: absolute; 
  color: black; 
  font-Size: 15px; 
  font-Family: 'Lexend'; 
  font-Weight: 900; 
  letter-Spacing: 1.08px; 
  
}
.Productonetxt{
  width: 260px; 
  height: 50.89px; 
  left: 24px; 
  top: 335.57px; 
  position: absolute; 
  color: black; 
  font-Size: 12px; 
  font-Family: 'Lexend'; 
  font-Weight: 200; 
  letter-Spacing: 1.08px; 
}
.proimg1{
  width: 182px;
  height: 151px; 
  left: 62px; 
  top: 77px; 
  position: absolute;
}
.proimg2{
  width: 146px;
  height: 146px; 
  left: 80px; 
  top: 79px; 
  position: absolute;
}
.product_detail_img-desktop{

  width: 50vw;
  height: 60vh;
  position:absolute;

 

}
.product_detail_img-desktop:hover{
  transform: scale(1.1);
  transition: 1.1s ease-in-out;
}
.product_detail_img-mobile{
  
    width: 90vw;
    height: 45vh;
    position:absolute;
    padding-left: 20px;
  
}
.medicin_2_card-desktop{
  display: flex;
 

}
.medicin_2_card-mobile{
  display: flex;
  flex-direction: column;
}
.medicin_pro_accor_ll-desktop{
  padding:50px 50px 0px 50px;
}

.medicin_pro_card-desktop{
  display:flex;
  flex-direction:column;
}
.medicin_pro_card-mobile{
  display:flex;
  flex-direction:column;
}
.medicin_pro_card2-desktop{
  margin-top:50px;
  background:#fff;
  min-height:15vh;
  padding:30px;
  margin-right: 100px;
  width:25vw;
}
.medicin_pro_card2-mobile{
  margin-top:50px;
  margin-left:8%; 
  background:#fff;
  min-height:15vh;
  padding:30px;
  width:80vw;
}
.medical_post_33-desktop{
  padding-left:10px
}
.medical_post-mobile{
  padding-left:20px
}
.produc_txt-desktop{
  margin: auto;
  margin-top: 60px;
  width: 50vw;
  max-height: 100vh;
  position: relative;
}
.produc_txt-mobile{

  
  padding-left: 30px;
  width: 95vw;
  max-height: 100vh;
  position: relative;
}
.productdetail_card-desktop{

  margin: auto;
  width: 50vw;
  height: 50vh;
  position: relative;
  min-height: 55vh; 
  
  
  justify-content: center;
  justify-self: center;
}
.productdetail_card-mobile{
  
    margin: auto;
    width: 95vw;
    height: 50vh;
    position: relative;
    min-height: 55vh; 
    
    
    justify-content: center;
    justify-self: center;
}
.product_detai_ltitel-desktop{

  color: black; 
  font-Size: 30px; 
  font-Family: 'Neuwelt'; 
  font-Weight: 600; 
  margin-bottom: 10px;
}
.product_detai_ltitel-mobile{
  color: black; 
  font-Size: 28px; 
  font-Family: 'Neuwelt'; 
  font-Weight: 600; 
  margin-bottom: 10px;
}
.productdetail_txt2-desktop{
  width: 50vw;
  
  padding-bottom: 20px;
  text-align: justify;
  color: black; 
  font-Size: 18px; 
  font-Family: 'Neuwelt light'; 
  font-Weight: 400; 
  line-height: 30px;
}
.productdetail_txt2-mobile{
  width: 80vw;
  left: 0px; 
  padding-bottom: 10px;
  text-align: justify;
  color: black; 
  font-Size: 15px; 
  font-Family: 'Neuwelt light'; 
  font-Weight: 400; 
  line-height: 18px;
}
.produc_detail_blog-desktop p{
  padding-left: 35px;
  font-size:15px;
  font-family: 'Neuwelt light';

}
.produc_detail_blog-mobile p{
  padding-left: 45px;
  font-size:15px;
  font-family: 'Neuwelt light';

}
.produc_detail_blog-desktop{
  
  border-bottom:1px solid #e5e6e7;

  padding-bottom:15px; 
}
.produc_detail_blog-mobile{
  
  border-bottom:1px solid #e5e6e7;
  margin-bottom:15px;
  padding-bottom:15px; 
}
.product_detail_image_sub{
  width:45px;
  height:45px
}
.Produc_details-desktop{
  background: #F9F7F1;
  padding: 50px;
  font-Family: 'Neuwelt'; 

  
  
}
.med_all-desktop{
  display:flex;

}
.med_all-mobile{
  display:flex;
  flex-direction: column;
 
}
.med_sub1_1-desktop{
  width: 60vw;
  padding-top:50px;
}
.med_sub1_1-mobile{
  width: 95vw;
  padding-top:20px;
  
}
.Produc_details-mobile{
  background: #F9F7F1;

}
.popular_post_content-desktop{
  display: flex;
  
}
.popular_post_content-mobile{
  display: flex;
  flex-direction: column;
}
.popular_post_content-desktop p{
  padding-left: 10px;
  font-size:15px;
  font-family: 'Neuwelt light';

}

.popular_post_content-mobile p{
  padding-left: 15px;
  font-size:15px;
  font-family: 'Neuwelt light';

}
.popular_post_content-mobile a{
  padding-left: 15px;
  font-size:15px;
  font-family: 'Neuwelt light';

}
.popular_post_content-desktop a{
  margin-left: 10px;
  font-size:15px;
  font-family: 'Neuwelt';

}
.med_icon-desktop{
  position: relative;
  right: 10px;
  top: 20px;
  color: #fff200;
  font-size:15px;
  

}
.med_icon-mobile{
  position: relative;
  
  top: 20px;
  color: #fff200;
  font-size:15px;
 

}
.medical_dev_detail_image{
  width:100%;
  height:0%;
  padding-top: 0px;
}
.medical_dev_image_sub-desktop{
  width:140px;
  height:140px;
  margin-top:5px;
transition: transform 0.3s ease-out;
}
.medical_dev_image_sub-desktop:hover{
  transform: scale(1.1);

}
.medical_dev_image_sub-mobile{
  width:180px;
  height:180px;
  top:0px;
transition: transform 0.3s ease-out;
}
.medical_dev_image_sub-mobile:hover{
  transform: scale(1.1);

}
.medical_dev_image_sub_special-desktop{
  width:140px;
  height:140px;
  margin-top:20px;
transition: transform 0.3s ease-out;
}
.medical_dev_image_sub_special-desktop:hover{
  transform: scale(1.1);

}
.medical_dev_image_sub_special-mobile{
  width:160px;
  height:160px;

transition: transform 0.3s ease-out;
}
.medical_dev_image_sub_special-mobile:hover{
  transform: scale(1.1);

}

.yellow_pro_detail_cover-desktop{
  background:linear-gradient(to left, rgba(0, 0, 0, 0), rgba(255, 242, 0));
  position: absolute;
  top: 0; 
  display: flex;
  flex-direction: row;
  left: 0;
  height: 76%;
  width: 100%;
  animation: fadeIn 5s
}
.yellow_pro_detail_cover-mobile{
  background:linear-gradient(to left, rgba(0, 0, 0, 0), rgba(255, 242, 0));
  position: absolute;
  top: 0; 
  display: flex;
  flex-direction: row;
  left: 0;
  height: 82%;
  width: 100%;
  animation: fadeIn 5s
}
.Grou_p199-desktop{
  height: 305px; 
  position: relative;
  justify-content:center;
}
.Grou_p199-mobile{
  height: 305px; 
  position: relative;
  justify-content:center;
}
.pro_detail_titel-desktop{
  text-Align:center;
  color: black; 
  font-Size: 55px; 
  font-Family: 'Neuwelt'; 
  font-Weight: 500;
  padding: 110px;
}
.pro_detail_titel-mobile{
  text-Align:center;
  color: black; 
  font-Size: 50px; 
  font-Family: 'Neuwelt'; 
  font-Weight: 500;
  padding: 120px 50px 0px 30px;
}