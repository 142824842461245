.outlined-btn {
    border: 2px solid black;
    background-color: white;
    color: black;
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
    text-decoration: none;
    border-radius: 7%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
  }
  .outlined-btn:hover {
    background-color: #ffff00;
    color: #000;
  }
  .fotter-sub{
    background: linear-gradient(to left, #000, #000000);
    width: 100%;
    height: 212px;
    left: 0px;
    top: 35px; 
    position: relative; 
    padding: 180px 60px;
    display: flex;
    justify-Content: center;
    align-Items: center;
    border-radius: 20px;
    
  }
  .footer-right{
    height: 30px;
    position: relative;
    display: flex;
    justify-Content:center;
    align-Content:center;
  }
  .footerRight-txt {
    top: 10px;
    position: relative;
    color: #6C727F; 
    font-Size: 12px;
    font-Family: 'Lexend';
    font-Weight: 400;
    
  }
  .fotter-icon{
    padding-right: 1000px;
    margin:0 auto;
    height: 55px;
  }
  .footerSub-txt1{
    height: 200px;
    position: absolute;
  }
  
  .footerSub-txt11{
   text-align: center;
    right: 0px;
    left: -350px;
    position: absolute;
    color: white;
    font-Size: 15px; 
    font-Weight: 700 ;
    width: 160px;
  
  }
  .footerSub-para{
    padding-top: 50px;
    right: 0px;
    left: -350px;
    position: absolute;
    color: white;
    font-Size: 15px; 
    width: 380px;
  }
  
  
  
  .footerSub-txt2{
    height: 200px;
    position: absolute;
  }
  
  .footerSub-txt22{
   text-align: center;
    right: 0px;
    left: 50px;
    position: absolute;
    color: white;
    font-Size: 15px; 
    font-Weight: 700 ;
    width: 160px;
  
  }
  .footerSub-para2{
    padding-top: 50px;
    right: 0px;
    left: 50px;
    position: absolute;
    color: white;
    font-Size: 15px; 
    width: 450px;
  }
  
  
  
  
  .footer_para3:hover{
    color: #FFFF00;
    cursor: pointer;
  }
  .footer_para:hover{
    color: #FFFF00;
    cursor: pointer;
  }
  .footer_para2:hover{
    color: #FFFF00;
    cursor: pointer;
  }
  .footer_para4:hover{
    color: #FFFF00;
    cursor: pointer;
  }
  .footer_para5:hover{
    color: #FFFF00;
    cursor: pointer;
  }
  .footer_para6:hover{
    color: #FFFF00;
    cursor: pointer;
  }
  .footer_para7:hover{
    color: #FFFF00;
    cursor: pointer;
  }
  .footer_para8:hover{
    color: #FFFF00;
    cursor: pointer;
  }
  .footer_para9:hover{
    color: #FFFF00;
    cursor: pointer;
  }
  .footer_para10:hover{
    color: #FFFF00;
    cursor: pointer;
  }
  .footer_para11:hover{
    color: #FFFF00;
    cursor: pointer;
  }
  .footerSub-txt3{
    height: 200px;
    position: absolute;
  }
  
  .footerSub-txt33{
   text-align: center;
   padding-left: 100px;
    right: 0px;
    left: 360px;
    position: absolute;
    color: white;
    font-Size: 15px; 
    font-Weight: 700 ;
    width: 50vh;
  
  }
  .footerSub-para3{
    padding-top: 50px;
    padding-left: 150px;
    right: 0px;
    left: 360px;
    position: absolute;
    color: white;
    font-Size: 15px; 
    width: 50vh;
  }
  
  
  
  
  
  
  
  .socialmedia {
    width: 400px;
    top: 250px;
    left: 60px;
    height: 100px;
    position: absolute;
    color: #fff;
  }
  .SubscribeUs{
    
    left: 0;
    top: 0; 
    position: absolute; 
    font-Size: 15px; 
    font-Weight: 400px; 
    letter-Spacing: 0.15px;
  }
  .socialmedia-icons{
    display:flex;
    gap:25px;
    flex-Direction:row;
    color:#000;
    margin-Top: 300px;
    margin-Right:-90px;
    position:absolute;
  
  }
  .icons{
    font-Size:30px;
  }
  .icons {
    font-size: 30px;
    color:#FFFF00;
    transition: transform 0.3s ease-out;
  }
  
  .icons:hover {
    transform: scale(1.2);
    color: #fff;
  }
  .footericons{
    margin-Right:10px;
    color: #FFFF00;
    transition: transform 0.3s ease-out;
  }
  .footericons:hover {
    transform: scale(1.2);
    color: #fff;
  }
  .fotterbutton{
    margin:10px 0 0 20px;
    background-color: #ffff00;
    text-align: center;
  }
  .facttxt1{
    text-Align:center;
    margin-Top:15px;
    margin-Left:auto;
    margin-Right:auto;  
    color: black; 
    font-Size: 20px;
    font-Family: Lexend; 
    font-Weight: 500;
  
  }
  .facttxt1::after {
    content: '';
    position: absolute;
    width: 36%;
    transform: scaleX(0);
    height: 3px;
    bottom: -1603px;
    left: 430px;
    background-color: #000;
    visibility: hidden;
    transition: all 0.3s ease-in-out 0s;
  }
  
  .facttxt1:hover::after {
    transform: scaleX(1);
    visibility: visible;
  }
  .Drogagrouptxt{
    text-Align:center;
    color: black; 
    padding-bottom: 50px;
    font-Size: 30px;
    font-Weight: 700;
  }
  .Drogagroup{
    background: rgba(243, 244, 246, 0.62);
    height: 350px;
    padding: 50px; 
  }
  .Drogapartener{
    background: rgba(243, 244, 246, 0.62);
    height: 300px;
    padding: 50px; 
    
  }
  .Drogagroupbrand{
    display:flex; 
    flex-Direction:row;
    justify-content: center;
  }
  .Drogapar{
    display:flex; 
    flex-Direction:row;
    gap: 70px;
  }
  .factcard2{
    right:90px; 
    width: 96px; 
    position: relative;  
    margin:auto;
  }
  
  
  
  .round11{
    transition: transform 0.3s ease-out;
  }
  .round11:hover {
    transform: scale(1.2);
  }
  
  .newdetailcard{
  
    width: 464px;
    margin:auto; 
    height: 383px; 
    position: relative;
    display: flex;
    justify-content: center;
  }
  
  .newdetailtxt{
    margin: auto;
    width: 590px;
    height: 300px;
    position: relative;
  }
  .proDescription::after {
    content: '';
    position: absolute;
    width: 105%;
    transform: scaleX(1);
    background-color: #F6F60F;
    visibility: visible;
    transition: all 0.3s ease-in-out 0s;
    height: 3.5px; 
    left: 0px; 
    top: 20px;
    
    
  }
  
  .proDescription:hover::after {
    transform: scaleX(0.5);
    visibility: visible;
  }
  
  .ProCategory{
    left: 0px;
    top: 130px; 
    position: absolute; 
    color: black; 
    font-Size: 24px; 
    font-Family: 'Lexend'; 
    font-Weight: 500; 
  }
  .ProCategorysub{
    left: 2px;
    top: 171px; 
    position: absolute; 
    color: black; 
    font-Size: 12px; 
    font-Family: 'Lexend'; 
    font-Weight: 400; 
    letter-Spacing: 0.40px; 
  }
  .proDescription{
    left: 0px; 
    top: 50px; 
    position: absolute; 
    color: black; 
    font-Size: 16px; 
    font-Family: 'Lexend'; 
    font-Weight: 400; 
    letter-Spacing: 0.50px; 
  }
  .proDetails{
    left: 120px;
    top: 50px; 
    position: absolute; 
    color: black; 
    font-Size: 16px;
    font-Family: 'Lexend'; 
    font-Weight: 400; 
    letter-Spacing: 0.50px; 
  
  }
  .newdetailtxt2{
    width: 590px;
    left: 0px; 
    top: 86px; 
    position: absolute; 
    color: black; 
    font-Size: 12px; 
    font-Family: 'Lexend'; 
    font-Weight: 400; 
    letter-Spacing: 0.40px; 
  }
  .newdetailtitel{
    left: 0px; 
    top: 0px; 
    position: absolute; 
    color: black; 
    font-Size: 24px; 
    font-Family: 'Lexend'; 
    font-Weight: 700; 
    letter-Spacing: 0.25px; 
  }
  
  .newsdetails-desktop{
    background: #F9F7F1;
    padding: 50px;

    font-Family: 'Neuwelt'; 
  }
  .newsdetails_5-mobile{
    background: #F9F7F1;
    width: 100% ;
    height: 420px;
  }
  .carrer_sub_titel-desktop{
    text-align: justify;
    line-height: 35px;
    color: black; 
    font-Size: 23px; 
    font-Family: 'Neuwelt light'; 
    font-Weight: 400;
    margin-top: 85px;
    width: 90%;
    padding: 0% 3% 0% 3% ;
  }
  .carrer_sub_titel-desktop p{
    color: black; 
    font-Size: 32px; 
    font-Family: 'Neuwelt'; 
    font-Weight: 800;
    margin-bottom: 30px;
  }
  .carrer_sub_titel-mobile{
    text-align: justify;
    line-height: 35px;
    color: black; 
    font-Size: 15px; 
    font-Family: 'Neuwelt light'; 
    font-Weight: 400;
    line-height: 1.5;
    padding: 10% 3% 0% 7% ;
  }
  .carrer_sub_titel-mobile p{
    color: black; 
    font-Size: 25px; 
    font-Family: 'Neuwelt'; 
    font-Weight: 800;
    margin-bottom: 30px;
  }
  .carrer_detailstitel-desktop{
    text-Align:center;
    color: black; 
    font-Size: 30px; 
    font-Family: 'Neuwelt'; 
    font-Weight: 400;
    margin-top: 90px;
  }
  .carrer_detailstitel-mobile{
    text-Align:center;
    color: black; 
    font-Size: 20px; 
    font-Family: 'Neuwelt'; 
    font-Weight: 400;
    padding-top: 80px;
    margin-bottom: 30px;
  }
  .job_detailstitel-desktop{
    text-Align:center;
    color: black; 
    font-Size: 30px; 
    font-Family: 'Neuwelt'; 
    font-Weight: 400;
    padding-top: 90px;
  }
  .job_detailstitel-mobile{
    text-Align:center;
    color: black; 
    font-Size: 20px; 
    font-Family: 'Neuwelt'; 
    font-Weight: 400;
    padding-top: 80px;
    margin-bottom: 30px;
  }
  .job_detailstitel_cards-desktop{
    display:flex;
    padding-right:-15px;
    padding-left:10%;
    padding-top:30px;
  }
  .job_sub_cards-desktop{
    flex:0 0 30%;
    max-width:60%;
  }
  .job_sub_cards-mobile{
    flex:0 0 100%;
    max-width:80%;
    padding-left: 40px;
  }
  .carrer_detailstitel p{
    text-Align:center;
    color: black; 
    font-Size: 30px; 
    font-Family: 'Neuwelt light'; 
    font-Weight: 400;
   
  }
  .carr_button-desktop {
    padding-top: 2%;
  }
  
  .carr_Bn-desktop {
    font-size: 15px;
    font-family: 'Neuwelt';
    border: none;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.3);
    background: #fff200;
  border-radius: 10px;
    padding: 10px 20px 10px;
    margin-left: 43%;
    align-items: center;
  }
  .carr_Bn-mobile{
    font-size: 15px;
    font-family: 'Neuwelt';
    border: none;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.3);
    background: #fff200;
    border-radius: 10px;
    padding: 10px 20px 10px;
    margin-left: 28%;
    align-items: center;
  }
  .job_Bn-desktop {
    font-size: 15px;
    font-family: 'Neuwelt';
    border: none;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.3);
    background: #fff200;
    padding-left: 0px;
    padding-right: 0px;
    padding: 10px 20px 10px;
    margin-left: 46%;
    margin-bottom: 5%;
    align-items: center;
    border-radius: 10px;
  }
  .job_Bn-mobile{
    font-size: 15px;
    font-family: 'Neuwelt';
    border: none;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.3);
    background: #fff200;
    padding-left: 0px;
    padding-right: 0px;
    padding: 10px 20px 10px;
    margin-left: 35%;
    margin-bottom: 5%;
    align-items: center;
    border-radius: 10px;
  }
  .mas_date_tex-mobile{
    font-size: 10px;
  }
  .Relatednewsstitel{
    margin-left: 40px;
    color: black; 
    font-Size: 32px; 
    font-Family: 'Lexend'; 
    font-Weight: 500;
    
  }
  .yellow_career_detail_cover_ex{
    background:linear-gradient(to left, rgba(0, 0, 0, 0), rgba(255, 242, 0));
    position: absolute;
    top: 0; 
    display: flex;
    flex-direction: row;
    left: 0;
    height: 80.6%;
    width: 100%;
    animation: fadeIn 5s
  }
  .yellow_career_detail_cover-mobile{
    background:linear-gradient(to left, rgba(0, 0, 0, 0), rgba(255, 242, 0));
    position: absolute;
    top: 0; 
    display: flex;
    flex-direction: row;
    left: 0;
    height: 92%;
    width: 100%;
    animation: fadeIn 5s
  }
  .carrer_detail_titel{
    text-Align:center;
    color: black; 
    font-Size: 55px; 
    font-Family: 'Neuwelt'; 
    font-Weight: 500;
    padding: 95px;
  }
  .carrer_detail_titel_7-mobile{
    text-Align:center;
    color: black; 
    font-Size: 45px; 
    font-Family: 'Neuwelt'; 
    font-Weight: 500;
    padding: 65px;
    padding-top: 120px;
  }
  .carr_cover{
    background: #F9F7F5;
    padding: 10% 3% 0% 3% ;
    
  }
  .carrer_desc-desktop{
    padding-left:10%;
    margin-Right:auto; 
    color: #59606C; 
    font-Size: 15px; 
    font-Family: 'Neuwelt Medium'; 
    font-Weight: 400; 
    line-height: 1.3;
    animation: slideInDown 2s;
    
  }
  .carrer_desc-mobile{
    padding-left:5%;
    margin-Right:auto; 
    color: #59606C; 
    font-Size: 15px; 
    font-Family: 'Neuwelt Medium'; 
    font-Weight: 400; 
    line-height: 1.3;
    animation: slideInDown 2s;
  
  }
  .carrer_alll-desktop{
    display:flex;
    width: 68vw; 
    justify-content: center;
  }
  .carrer_alll-mobile{
    display:flex;
    flex-direction: column;
    width: 90%; 
    justify-content: center;
  }
  .carrer_tit2-desktop{
   
    font-Size: 32px; 
    font-Family: 'Neuwelt light';
    color: #000; 
    
    animation: slideInDown 2s
  }
  .carrer_tit2-mobile{
     
    font-Size: 20px; 
    font-Family: 'Neuwelt light';
    color: #000; 
    
    animation: slideInDown 2s
  }
  .carrer_text_sub_2-desktop{
    font-family:'Neuwelt light';
    font-size:20px;
  }
  .carrer_text_sub_2-mobile{
    font-family:'Neuwelt light';
    font-size:15px;
    line-height: 1.2;
    text-align: justify;
  }
  .carrer_text_sub-mobile{
    font-family:'Neuwelt light';
    font-size:15px; 
    line-height:1.2;
    width:100%;
  }
  .carrer_text_sub-desktop{
    font-family:'Neuwelt light';
    font-size:17px; 
    line-height:1.2;
    width:100%;
  }
  .carrer_txt_2-desktop{
    width:100%;
    margin-top: 50px;
    display: flex;
    flex-direction: row;
  }
  .carrer_txt_2-mobile{
    width:100%;
    margin-top: 50px;
    display: flex;
    flex-direction: row;
  }
  .carrer_txt_3-desktop{
    width:100%;
    padding-top: 30px;
    display: flex;
    flex-direction: row;
  }
  .carrer_txt_3-mobile{
    width:100%;
    margin-top: 30px;
    display: flex;
    flex-direction: row;
  }
  .carrer_txt_4-desktop{
    width:100%;
    margin-top: 30px;
    display: flex;
    flex-direction: row;
  }
  .carrer_txt_4-mobile{
    width:100%;
    margin-top: 25px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: row;
  }
  .carrer_sub1-desktop{
    font-Size: 16px; 
    padding-top: 3%;
   text-align: justify;
   width: 180%;
    font-Family: 'Neuwelt'; 
    font-Weight: 200;
    animation: slideInDown 2s
  }
  .carrer_sub1-mobile{
    font-Size: 16px; 
    padding-top: 3%;
   text-align: justify;
    font-Family: 'Neuwelt'; 
    font-Weight: 200;
    width: 120%;
    animation: slideInDown 2s
  }
  .carrer_sub1_2-mobile{
    font-Size: 16px; 
    padding-top: 3%;
   text-align: justify;
    font-Family: 'Neuwelt'; 
    font-Weight: 200;
    width: 155%;
    animation: slideInDown 2s
  }
  .carrer_sub1_1-mobile{
    font-Size: 16px; 
    padding-top: 3%;
   text-align: justify;
    font-Family: 'Neuwelt'; 
    font-Weight: 200;
    width: 120%;
    animation: slideInDown 2s
  }
  .carrer_sub1_3-mobile{
    font-Size: 16px; 
    padding-top: 3%;
   text-align: justify;
    font-Family: 'Neuwelt'; 
    font-Weight: 200;
    width: 250%;
    animation: slideInDown 2s
  }
  .carrer_img-desktop{
    
    width: 600px; 
    height: 600.81px; 
    border-Radius: 10px;
    padding-bottom:0%; 
    padding-left: 30px;
    padding-right:-30%;
    animation: slideInLeft 2s;
  }
  .carrer_img-mobile{
      
      width:75%; 
      height: 50%; 
      border-Radius: 10px;
      padding-top:10%; 
      padding-left: 10px;
      margin-right:-30%;
      animation: slideInLeft 2s;
  }
  .carrer_img_tex_sub-desktop{
    display: flex;
   
  }
  .carrer_bullet-desktop{
    display:'flex';
    padding-left: 150px;
    padding-top: 50px;
  }
  .mas-desktop {
    border-radius: 30px;
    padding: 15px;
    margin: 0px 0px 20px 0px;
    background-color: var(--e-global-color-e9c5ff0);
    box-shadow: 0px 0px 0px 0px rgb(277, 277, 277, 0.5);
    display: flex;
    width: 55vw;
    transition: box-shadow 0.3s;
  }
  
  .mas-desktop:hover {
  box-shadow: 0px 0px 10px 0px  rgba(161, 161, 161, 0.5);
  }
  .mas-desktop .job_img {
    transition: transform 0.3s ease-out;
  }
  
  .mas-desktop:hover .job_img {
    transform: scale(1.2);
  }
  .mas-mobile{
    border-radius: 30px;
    padding-left: 10px;
    padding-top: 20px;
    margin: 0px 0px 20px 0px;
    background-color: var(--e-global-color-e9c5ff0);
    box-shadow: 0px 0px 0px 0px rgb(277, 277, 277, 0.5);
    display: flex;
    width: 75vw;
    transition: box-shadow 0.3s;
  }
  .mas-mobile:hover {
    box-shadow: 0px 0px 10px 0px  rgba(161, 161, 161, 0.5);
    }
  .mas-mobile .job_img {
      transition: transform 0.3s ease-out;
    }
  .mas-mobile:hover .job_img {
      transform: scale(1.2);
    }
  .mas_img-mobile{
    max-Width: 42%;
    
   
  }
  .mas_date_ico-desktop{
    text-align: left; 
    padding: 0px 0px 10px 0px; 
    -webkit-tap-highlight-color: transparent;
    width:200%; 
  }

  .carrer_titiel_sub-desktop{
    font-family:'Neuwelt';
    width:100%;
    text-align:justify
  }
  .carrer_titiel_sub_2-mobile{
    font-family:'Neuwelt';
    width:60%;
  }
  .carrer_titiel_sub_2-desktop{
    font-family:'Neuwelt';
    width:90%;
  }
  .carrer_titiel_sub-mobile{
    font-family:'Neuwelt';
    width:80%;
    text-align:justify
  }
  .mas_date_ico_sub-desktop{
    display:flex;
    flex-direction: column;
    width: 100%;
  }
  .mas_date_ico_sub-mobile{
    display:flex;
   width: 150px;
  }
  .mas_date-desktop{
    margin: 0px 10px 0px 0px;
    cursor: pointer;
    width:30%;
  }
  .mas_date-mobile{
    margin: 0px 10px 0px 0px;
    cursor: pointer;
    width:80%;
    display: flex;
    flex-direction: row;
  }
  .mas_date_ico-desktop{
    position: relative;
    right: 5px;
    top: 5px;
    color: gray;
    font-size:20px;
    padding-top: 20px;
  }
  
  .mas_date_tex-desktop{
    color:gray ;
    font-family:'Neuwelt light';
    font-size: 15px;
  }
  .mas_date_ico-mobile{
    color:gray ;
    font-family:'Neuwelt light';
    font-size: 10px;
     position: relative;
     margin-right: 5px;
  margin-top: 2px;
     -webkit-tap-highlight-color: transparent;
     width:10%; 
  
  }
  .mas_date_ico_2-mobile{
    color:gray ;
    font-family:'Neuwelt light';
    font-size: 10px;
     position: relative;
     margin-right: 5px;
     margin-top: 2px;
     -webkit-tap-highlight-color: transparent;
     width:15%; 
  }
  .mas_date_ico_3-desktop{
    position: relative;
    right: -5px;
    top: 5px;
    color: gray;
    font-size:16px;
  
  }
  .mas_date_ico_3-mobile{
    position: relative;
    right: -5px;
    top: 0px;
    color: gray;
    font-size:16px;
    margin-top: 0px;
  }
  .mas_cata-mobile{
  display: flex;
  }
  .mas_date_tex_2-desktop{
    color:#fff200 ;
    font-family:'Neuwelt'; 
    font-size:16px;
    text-decoration:none;
    width: 100%;
  }
  .mas_date_tex_5-desktop{
    color:#fff200 ;
    font-family:'Neuwelt'; 
    font-size:15px;
    text-decoration:none;
    
  }
  .mas_date_tex_6-desktop{
    color:#fff200 ;
    font-family:'Neuwelt'; 
    font-size:16px;
    text-decoration:none;
    
  }
  .mas_date_tex_2-mobile{
    color:#fff200 ;
    font-family:'Neuwelt'; 
    font-size:12px;
    width: 60px;
    margin-top: 0px;
    text-decoration:none;
  }
  .job_img {
    max-Width: 70%;
    height: auto;
    margin-top: 15%;
  }