.outlined-btn {
  border: 2px solid black;
  background-color: white;
  color: black;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
  border-radius: 7%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
}
.outlined-btn:hover {
  background-color: #fff200;
  color: #000;
}
.fotter-sub{
  background: linear-gradient(to left, #000, #000000);
  width: 100%;
  height: 212px;
  left: 0px;
  top: 35px; 
  position: relative; 
  padding: 180px 60px;
  display: flex;
  justify-Content: center;
  align-Items: center;
  border-radius: 20px;
  
}
.abutfooter-right{
  height: 30px;
  position: relative;
  background: #000;
  display: flex;
  justify-Content:center;
  align-Content:center;
}
.abutfooterRight-txt {
  top: 10px;
  position: relative;
  color: #6C727F; 
  font-Size: 12px;
  font-Family: 'Neuwelt medium';
  font-Weight: 400;
  
}
.abutfotter-icon{
  padding-right: 80%;
  color: #000;
  margin:0 auto;
  height: 55px;
}
.abutfooterSub-txt1{
  
  height: 200px;
  position: absolute;
}


.abutfooterSub-txt11{
  text-align: center;
   right: 0px;
   left: -320px;
   position: absolute;
   color: #000;
   font-Size: 30px; 
   font-Weight: 700 ;
   width: 160px;
   
 
 }
 .abutfooterSub-para{
  padding-top: 75px;
  right: 0px;
  left: -380px;
  position: absolute;
  color: #000;
  font-Size: 15px; 
  width: 380px;
}

.abutfootersubtxt3{
  margin-left: 17%;
  font-Size: 16px; 
  font-Family: 'Neuwelt'; 
  font-Weight: 200;
  animation: slideInDown 2s;
  margin-bottom:20px;
}


.abutfooterSub-txt2{
  height: 200px;
  position: absolute;
}
.abutfooterSub-txt22{
  text-align: center;
   right: 0px;
   left: 110px;
   position: absolute;
   color: #000;
   font-Size: 30px; 
   font-Weight: 700 ;
   width: 160px;
 
 }
 .abutfooterSub-para2{
  padding-top: 75px;
  right: 0px;
  left: 15px;
  position: absolute;
  color: #000;
  font-Size: 15px; 
  width: 450px;
}




.footer_para3:hover{
  color: #FFF200;
  cursor: pointer;
}
.footer_para:hover{
  color: #FFF200;
  cursor: pointer;
}
.footer_para2:hover{
  color: #FFF200;
  cursor: pointer;
}
.footer_para4:hover{
  color: #FFF200;
  cursor: pointer;
}
.footer_para5:hover{
  color: #FFF200;
  cursor: pointer;
}
.footer_para6:hover{
  color: #FFF200;
  cursor: pointer;
}
.footer_para7:hover{
  color: #FFF200;
  cursor: pointer;
}
.footer_para8:hover{
  color: #FFF200;
  cursor: pointer;
}
.footer_para9:hover{
  color: #FFF200;
  cursor: pointer;
}
.footer_para10:hover{
  color: #FFF200;
  cursor: pointer;
}
.footer_para11:hover{
  color: #FFF200;
  cursor: pointer;
}
.abutfooterSub-txt3{
  height: 200px;
  position: absolute;
}

.abutfooterSub-txt33{
  text-align: center;
  padding-left: 100px;
   right: 0px;
   left: 350px;
   position: absolute;
   color: #000;
   font-Size: 30px; 
   font-Weight: 700 ;
   width: 50vh;
 
 }
 .abutfooterSub-para3{
  padding-top: 75px;
  padding-left: 190px;
  right: 0px;
  left: 250px;
  position: absolute;
  color: #000;
  font-Size: 15px; 
  width: 70vh;
}








.socialmedia {
  width: 400px;
  top: 250px;
  left: 60px;
  height: 100px;
  position: absolute;
  color: #fff;
}
.SubscribeUs{
  
  left: 0;
  top: 0; 
  position: absolute; 
  font-Size: 15px; 
  font-Weight: 400px; 
  letter-Spacing: 0.15px;
}
.socialmedia-icons{
  display:flex;
  gap:25px;
  flex-Direction:row;
  color:#000;
  margin-Top: 300px;
  margin-Right:-90px;
  position:absolute;

}
.icons{
  font-Size:30px;
}
.icons {
  font-size: 30px;
  color:#FFF200;
  transition: transform 0.3s ease-out;
}

.icons:hover {
  transform: scale(1.2);
 
}
.footericon{
  margin-Right:10px;
  color: #000;
  transition: transform 0.3s ease-out;
}
.footericon:hover {
  transform: scale(1.2);
  
}
.fotterbutton{
  margin:10px 0 0 20px;
  background-color: #fff200;
  text-align: center;
}
.facttxt1{
  text-Align:center;
  margin-Top:15px;
  margin-Left:auto;
  margin-Right:auto;  
  color: black; 
  font-Size: 20px;
  font-Family: Lexend; 
  font-Weight: 500;

}
.facttxt1::after {
  content: '';
  position: absolute;
  width: 36%;
  transform: scaleX(0);
  height: 3px;
  bottom: -1603px;
  left: 430px;
  background-color: #000;
  visibility: hidden;
  transition: all 0.3s ease-in-out 0s;
}

.facttxt1:hover::after {
  transform: scaleX(1);
  visibility: visible;
}
.Drogagrouptxt{
  text-Align:center;
  color: black; 
  padding-bottom: 50px;
  font-Family: 'Neuwelt'; 
  font-Weight: 800;
  font-Size: 32px; 
}
.Drogagrouptxt2{
  text-Align:center;
  color: black; 
  padding-bottom: 30px;
  font-Family: 'Neuwelt'; 
  font-Weight: 800;
  font-Size: 32px; 
}
.Drogagrouptxt2-mobile{
  text-Align:center;
  color: black; 
  padding-bottom: 10px;
  font-Family: 'Neuwelt'; 
  font-Weight: 800;
  font-Size: 30px; 
}
.Drogagroup{
  background: rgba(263, 254, 256, 0.85);
  height: 350px;
  padding: 50px; 
}
.Droga_partener{
  background:white;
  height: 300px;
  padding: 50px; 
  
}
.Droga_partener-mobile{
  background:white;
  height: 350px;
  padding: 70px; 
}
.Drogagroupbrand{
  display:flex; 
  flex-Direction:row;
  justify-content: center;
}
.Drogapar{
  display:flex; 
  flex-Direction:row;
  gap: 70px;
}
.factcard2{
  right:90px; 
  width: 96px; 
  position: relative;  
  margin:auto;
}



.round11{
  transition: transform 0.3s ease-out;
}
.round11:hover {
  transform: scale(1.2);
}



.Aboutus{
  background: #F9F7F1;
  padding: 40px;
  font-Family: 'Neuwelt'; 
}
.Aboutus_all-desktop{
  display:flex ; 
  width: 90vw; 
  justify-content: center;
  padding-top:50px; 
  flex-direction: column;
}
.Aboutus_all-mobile{
  display:flex ; 
  width: 80vw; 
  justify-content: center;
  padding-top:0px; 
  flex-direction: column;
}
.Aboutustitel{
  text-Align:center;
  color: black; 
  font-Size: 55px; 
  font-Family: 'Neuwelt'; 
  font-Weight: 500;
  padding: 150px;
}
.Aboutustitel-mobile{
  padding-top: 30%;
  color: black  ; 
  text-align: center;

  font-Size: 50px; 
  font-Family: 'Neuwelt'; 
  font-Weight: 800;
  animation: slideInDown 2s
}
.Aboutussubtitle{
  color: black; 
  text-align: center;
  font-Family: 'Neuwelt'; 
  font-Weight: 800;
  font-Size: 32px; 
  padding-bottom: 15px;
}
.Aboutussubtitle-mobile{
  color: black; 
  text-align: center;
  font-Family: 'Neuwelt'; 
  font-Weight: 800;
  font-Size: 25px; 
  padding-bottom: 15px;
}
.Image21-desktop{
  width: 50%;
  
  height: 400px;
  animation: slideInLeft 2s;
  border-radius:30px;
}
.Image21-mobile{
  width: 250%;
  height: 250px;
  margin-top: 30px;
  animation: slideInLeft 2s;
  border-radius:0px;
}
.Aboutus_img_txt-desktop{
  display: flex;
  padding-left: 90px;
  flex-direction: row; 
}
.Aboutus_img_txt-mobile{
  display: flex;
  flex-direction: column; 
}
.Aboutus_img_txt_2-desktop{
  display: flex;
  flex-direction: row-reverse;
  padding-top: 10px;
}
.Aboutus_img_txt_2-mobile{
  display: flex;
  flex-direction: column; 
}
.Group19_9-desktop{
  height: 305px;
  
  position: relative;
  justify-content:center;
}
.Group19_9-mobile{
  height: 185px;
  position: relative;
  justify-content:center;
}
.Group197-desktop{
  height: 106px; 
  left: 120px;
  position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Group197-mobile{
  height: 106px; 
  
  top: 163px ;
  position: absolute;
}
.Group156{
  width: 124px;
  height: 122px; 
  left: 10px; 
  top: 0; 
  position: absolute;
}
.Group156-mobile{
  width: 124px;
  height: 122px; 
  left: 0px; 
  top: 0; 
  position: absolute;
}
/* .aboutimg{
  width: 480px; 
  height: 410.81px; 
  border-Radius: 10px;
  margin-Right:80px; 
  margin-Left:80px;
} */
.aboutdesc-desktop{
  padding-top:50px;
padding-left: 50px;

  text-align: justify;
  color: #59606C; 
  font-Size: 16px; 
  font-Family: 'Neuwelt medium'; 
  font-Weight: 400; 
  letter-Spacing: 2px;
  line-height: 190%;
  width: 60%;
  animation: slideInDown 2s;
}
.aboutdesc-mobile{
  margin-Top:50px;
  margin-Left:auto;
  margin-Right:auto; 
  text-align: justify;
  color: #59606C; 
  font-Size: 16px; 
  font-Family: 'Neuwelt medium'; 
  font-Weight: 400; 
  letter-Spacing: 1px;
  line-height: 150%;
  
  animation: slideInDown 2s;
}
.aboutdesc_2-desktop{
  padding-top:80px;
padding-left: 80px;
padding-right: 50px;

  text-align: justify;
  color: #59606C; 
  font-Size: 16px; 
  font-Family: 'Neuwelt medium'; 
  font-Weight: 400; 
  letter-Spacing: 2px;
  line-height: 190%;
  width: 55%;
  animation: slideInDown 2s;
}
.aboutdesc_2-mobile{
  margin-Top:50px;
  margin-Left:auto;
  margin-Right:auto; 
  text-align: justify;
  color: #59606C; 
  font-Size: 16px; 
  font-Family: 'Neuwelt medium'; 
  font-Weight: 400; 
  letter-Spacing: 1px;
  line-height: 150%;
  
  animation: slideInDown 2s;
}
.yellow_aboutcover{
  background:linear-gradient(to left, rgba(0, 0, 0, 0), rgba(255, 242, 0));
  position: absolute; /* This makes it overlay the image */
  top: 0; 
  display: flex;
  flex-direction: row;
  left: 0;
  height: 100%;
  width: 100%;
  animation: fadeIn 5s
}
.yellow_aboutcover2-desktop{
  background:linear-gradient(to left, rgba(0, 0, 0, 0), rgba(255, 242, 0));
  position: absolute; /* This makes it overlay the image */
  top: -50px; 
  display: flex;
  flex-direction: row;
  left: -400px;
  height: 133%;
  width: 100%;
  animation: fadeIn 5s
}
.yellow_aboutcover2-mobile{
  background:linear-gradient(to left, rgba(0, 0, 0, 0), rgba(255, 242, 0));
  position: absolute; /* This makes it overlay the image */
  top: -65px; 
  display: flex;
  flex-direction: row;
  left: -90px;
  height: 170.8%;
  width: 100%;
  animation: fadeIn 5s
}
.MissionVision{
  text-align:center;
  color: black; 
  font-size: 40px; 
  font-family: 'Neuwelt'; 
  font-weight: 700 ;
  animation: slideInDown 2s;
}
.MissionVision-mobile{
  text-align:center;
  color: black; 
  font-size: 30px; 
  font-family: 'Neuwelt'; 
  font-weight: 700 ;
  animation: slideInDown 2s;
}
.Group198-desktop{
  height: 106px;

left: 120px;
position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Group198-mobile{
  height: 106px;
  top: 65px; 
  position: absolute;
}
.Group157{
  width: 102px;
  height: 106px; 
  left: 0; 
  top: 0; 
  position: absolute;
}
.Group157-mobile{
  width: 50px;
  height: 50px; 
  left: 0; 
  top: 0; 
  
}
.Group156{
  width: 124px;
  height: 122px; 
  left: 10px; 
  top: 0; 
  position: absolute
}
.Group156-mobile{
  width: 50px;
  height: 50px; 
  left: 0; 
  top: 0; 
}
.Mission_sub{
  right: 25px;
  top: 79px; 
  position: absolute;
  color: black;
  font-size: 34px; 
  font-family: 'Neuwelt'; 
  font-weight: 400; 
  letter-spacing: 0.25;
  animation: slideInDown 2s;
}
.Mission_sub-mobile{
  left: 0; 
  top: 35px; 
  position: absolute; 
  color: black; 
  font-size: 20px; 
  font-family: 'Neuwelt';
  font-weight: 400;
  letter-spacing: 0.25;
  animation: slideInDown 2s;
}
.footericons{
  left: 25px; 
  top: 15px; 
  position: absolute; 
  color: #FFF; 
  font-size: 44px;  
  font-weight: 400; 
  animation: slideInDown 2s;
}
.footericons-mobile{
  left: 15px; 
  top: 5px; 
  position: absolute; 
  color: #FFF; 
  font-size: 25px;  
  font-weight: 400; 
  animation: slideInDown 2s;
}
.footericons_2{
  left: 15px; 
  top: 20px; 
  position: absolute; 
  color: #fff200; 
  font-size: 44px;  
  font-weight: 400; 
  animation: slideInDown 2s;
}
.footericons_2:hover{
  color: #fff;
  transform: scale(1.1);
  transition: 0.5s ease-in-out;
}
.Vision_sub{
  left: 0; 
  top: 63px; 
  position: absolute; 
  color: black; 
  font-size: 34px; 
  font-family: 'Neuwelt';
  font-weight: 400;
  letter-spacing: 0.25;
  animation: slideInDown 2s;
}
.Vision_sub-mobile{
  left: 0; 
  top: 35px; 
  position: absolute; 
  color: black; 
  font-size: 20px; 
  font-family: 'Neuwelt';
  font-weight: 400;
  letter-spacing: 0.25;
  animation: slideInDown 2s;
}
.Mission-desktop{
  padding:50px 180px;
  position: relative; 
  color: black; 
  font-size: 34px; 
  font-family: 'Neuwelt';
  font-weight: 400; 
  letter-spacing: 0.25;
  animation: slideInDown 2s;
}
.Mission-mobile{
  padding:65px 60px;
  position: relative; 
  color: black; 
  font-size: 34px; 
  font-family: 'Neuwelt';
  font-weight: 400; 
  letter-spacing: 0.25;
  animation: slideInDown 2s;
}
.ToBeTh{
  width: 715px; 
  left: 133px; 
  top: 25px; 
  position: absolute; 
  color: #fff; 
  font-size: 16px; 
  font-family: 'Neuwelt';
  font-weight: 400; 
  letter-spacing: 0.15;
  animation: slideInDown 2s;
  background-color: #000;
  padding: 25px;
  border-radius: 5px;
}
.ToBeTh-mobile{
  width: 225px; 
  left: 70px; 
  top: 15px; 
  position: absolute; 
  color: #fff; 
  font-size: 10px; 
  font-family: 'Neuwelt';
  text-align: justify;
  font-weight: 400; 
  letter-spacing: 0.15;
  animation: slideInDown 2s;
  background-color: #000;
  padding: 5px;
  border-radius: 5px;
}
.WeBuildEtn{
  width: 715px;
  left: 130px; 
  top: 40px; 
  position: absolute; 
  color: #000; 
  font-size: 16px; 
  font-family: 'Neuwelt'; 
  font-weight: 400; 
  letter-spacing: 0.15;
  animation: slideInDown 2s;
  background-color: #ffff00;
  padding: 20px;
  border-radius: 5px;
}
.WeBuildEtn-mobile{
  width: 225px; 
  left: 70px; 
  top: -10px; 
  position: absolute; 
  color: #fff; 
  font-size: 10px; 
  font-family: 'Neuwelt';
  text-align: justify;
  font-weight: 400; 
  letter-spacing: 0.15;
  animation: slideInDown 2s;
  background-color: #000;
  padding: 5px;
  border-radius: 5px;
}
.value_tit{
  display:flex;
  justify-content:center; 
  align-items:center;
  margin-bottom:30px;
}
.value_tit-mobile{
  display:flex;
  justify-content:center; 
  align-items:center;
  margin-bottom:30px;
}
.value_tit_sub{
  color: black;
  font-size: 32px; 
  font-family: 'Neuwelt'; 
  font-weight: 800;
  animation: slideInDown 2s;
}
.value_tit_sub-mobile{
  color: black; 
  text-align: center;
  font-Family: 'Neuwelt'; 
  font-Weight: 800;
  font-Size: 30px; 
  padding-bottom: 15px;
}
.Ourvalues{

  display:flex;
  flex-direction:column;
  padding:40px 30px 40px 30px;
}
.Ourvalues-mobile{

  display:flex;
  flex-direction:column;
  padding:60px;
}
.value_cont_1{
  display:flex;
  flex-direction:row; 
  justify-content:center; 
  align-items:center; 
  gap:80px;
}
.value_cont_1-mobile{
  display:flex;
  flex-direction:column; 
  justify-content:center; 
  align-items:center; 
  gap:20px;
}
.value_cont_2{
  display:flex;
  flex-direction:row; 
  justify-content:center; 
  align-items:center; 
  gap:80px;
}
.value_cont_2-mobile{
  display:flex;
  flex-direction:column; 
  justify-content:center; 
  align-items:center; 
  gap:20px;
}
.humanity{
  width: 464px;
  height: 168px; 
  position: relative;
  margin-bottom:30px;
  margin-top:30px;
}
.humanity-mobile{
  width: 264px;
  height: 168px; 
  position: relative;
  margin-bottom:0px;
  
}
.integrity{
  width: 464px;
  height: 168px; 
  position: relative;
  margin-bottom:30px;
  margin-top:30px;
}
.integrity-mobile{
  width: 264px;
  height: 168px; 
  position: relative;
  margin-top:100px;
}
.Commitment{
  width: 464px;
  height: 168px; 
  position: relative;
  margin-bottom:30px;
  margin-top:30px;
}
.Commitment-mobile{
  width: 264px;
  height: 168px; 
  position: relative;
  margin-top:60px;
}
.CustomerCentric{
  width: 464px;
  height: 168px; 
  position: relative;
  margin-bottom:30px;
  margin-top:30px;
}
.CustomerCentric-mobile{
  width: 264px;
  height: 168px; 
  position: relative;
  margin-top:90px;
}
.value_icon{
  left: 200px;
  top: 5px; 
  position: absolute; 
  font-size: 44px;  
  font-weight: 400; 
  animation: slideInDown 2s;
}
.value_icon-mobile{
  left: 110px;
  top: 5px; 
  position: absolute; 
  font-size: 40px;  
  font-weight: 400; 
  animation: slideInDown 2s;
}
.value_icon_2-desktop{
  left: 200px;
  top: 10px; 
  position: absolute; 
  font-size: 52px;  
  font-weight: 400; 
  animation: slideInDown 2s;
}
.value_icon_2-mobile{
  left: 110px;
  top: 5px; 
  position: absolute; 
  font-size: 40px;  
  font-weight: 400; 
  animation: slideInDown 2s;
}
.value_icon_3{
  left: 200px;
  top: 5px; 
  position: absolute; 
  font-size: 44px;  
  font-weight: 400; 
  animation: slideInDown 2s;
}
.value_icon_3-mobile{
  left: 110px;
  top: 5px; 
  position: absolute; 
  font-size: 40px;  
  font-weight: 400; 
  animation: slideInDown 2s;
}
.value_icon_4{
  left: 200px;
  top: 5px; 
  position: absolute; 
  font-size: 44px;  
  font-weight: 400; 
  animation: slideInDown 2s;
}
.value_icon_4-mobile{
  left: 110px;
  top: 5px; 
  position: absolute; 
  font-size: 40px;  
  font-weight: 400; 
  animation: slideInDown 2s;
}
.humanitytitel-desktop{
  left: 168px; 
  top: 49px; 
  position: absolute; 
  color: black; 
  font-size: 24px; 
  font-family: 'Neuwelt'; 
  font-weight: 400;
}
.humanitytitel-mobile{
  left: 85px; 
  top: 49px; 
  position: absolute; 
  color: black; 
  font-size: 20px; 
  font-family: 'Neuwelt'; 
  font-weight: 400;
}
.integritytitel-desktop{
  left: 168px; 
  top: 45px; 
  position: absolute; 
  color: black; 
  font-size: 24px; 
  font-family: 'Neuwelt'; 
  font-weight: 400;
}
.integritytitel-mobile{
  left: 95px; 
  top: 49px; 
  position: absolute; 
  color: black; 
  font-size: 20px; 
  font-family: 'Neuwelt'; 
  font-weight: 400;
}
.Commitmenttitel-desktop{
  left: 158px; 
  top: 49px; 
  position: absolute; 
  color: black; 
  font-size: 24px; 
  font-family: 'Neuwelt'; 
  font-weight: 400;
}
.Commitmenttitel-mobile{
  left: 75px; 
  top: 49px; 
  position: absolute; 
  color: black; 
  font-size: 20px; 
  font-family: 'Neuwelt'; 
  font-weight: 400;
}
.CustomerCentrictitel-desktop{
  left: 138px; 
  top: 49px; 
  position: absolute; 
  color: black; 
  font-size: 24px; 
  font-family: 'Neuwelt'; 
  font-weight: 400;
}
.CustomerCentrictitel-mobile{
  left: 55px; 
  top: 49px; 
  position: absolute; 
  color: black; 
  font-size: 20px; 
  font-family: 'Neuwelt'; 
  font-weight: 400;
}
.humanitytxt{
  line-height:1.5;
  width: 464px; 
  height: 85px; 
  left: 0; 
  top: 83px; 
  position: absolute; 
  text-align: justify; 
  color: #59606C; 
  font-size: 16px; 
  font-family: 'Neuwelt light'; 
  font-weight: 400; 
  letter-spacing: 0.15; 
  
}
.humanitytxt-mobile{
  line-height:1.5;
  width: 254px; 
  height: 85px; 
  left: 0px; 
  top: 83px; 
  position: absolute; 
  text-align:justify; 
  color: #59606C; 
  font-size: 15px; 
  font-family: 'Neuwelt light'; 
  font-weight: 400; 
  letter-spacing: 0.15; 
}
.integritytxt{
  line-height:1.5;
  width: 464px; 
  height: 85px; 
  left: 0; 
  top: 83px; 
  position: absolute; 
  text-align: justify; 
  color: #59606C; 
  font-size: 16px; 
  font-family: 'Neuwelt light'; 
  font-weight: 400; 
  letter-spacing: 0.15; 
}
.integritytxt-mobile{
  line-height:1.5;
  width: 254px; 
  height: 85px; 
  left: 0px; 
  top: 83px; 
  position: absolute; 
  text-align:justify; 
  color: #59606C; 
  font-size: 15px; 
  font-family: 'Neuwelt light'; 
  font-weight: 400; 
  letter-spacing: 0.15; 
}
.Commitmenttext{
  line-height:1.5;
  width: 464px; 
  height: 85px; 
  left: 0; 
  top: 83px; 
  position: absolute; 
  text-align: justify; 
  color: #59606C; 
  font-size: 16px; 
  font-family: 'Neuwelt light'; 
  font-weight: 400; 
  letter-spacing: 0.15; 
}
.Commitmenttext-mobile{
  line-height:1.5;
  width: 254px; 
  height: 85px; 
  left: 0px; 
  top: 83px; 
  position: absolute; 
  text-align:justify; 
  color: #59606C; 
  font-size: 15px; 
  font-family: 'Neuwelt light'; 
  font-weight: 400; 
  letter-spacing: 0.15; 
}
.CustomerCentrictxt{
  line-height:1.5;
  width: 464px; 
  height: 85px; 
  left: 0; 
  top: 83px; 
  position: absolute; 
  text-align: justify; 
  color: #59606C; 
  font-size: 16px; 
  font-family: 'Neuwelt light'; 
  font-weight: 400; 
  letter-spacing: 0.15; 
}
.CustomerCentrictxt-mobile{
  line-height:1.5;
  width: 254px; 
  height: 85px; 
  left: 0px; 
  top: 83px; 
  position: absolute; 
  text-align:justify; 
  color: #59606C; 
  font-size: 15px; 
  font-family: 'Neuwelt light'; 
  font-weight: 400; 
  letter-spacing: 0.15; 
}
@keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}
@keyframes slideInLeft {
  0% {
      transform: translateX(-100%);
      opacity: 0;
  }
  100% {
      transform: translateX(0);
      opacity: 1;
  }
}
@keyframes slideInDown {
  0% {
      transform: translateY(50%);
      opacity: 0;
  }
  100% {
      transform: translateX(0);
      opacity: 1;
  }
}
.slider {
  overflow: hidden;
  justify-items: center;
  
  height: 250px;
  margin: auto;
  position: relative;
  width: 100%;
  display: grid;
  place-items: center;
 
}

.slide-track {
  display: flex;
  animation: scroll 15s linear infinite;
  width: calc(250px * 18);
}
.slide-track:hover{
  animation-play-state: paused;
}
.slide {
  width: 250px;
  height: 200px;
  padding: 15px;
  flex-shrink: 0;
  align-items: center;
  overflow: hidden;
  perspective: 180px;
  margin-bottom: 15%;
}

.imgs{
  width: 40%;
  transition: transform 1s;
}

.imgs:hover{
  transform: translateZ(20px);
}
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 9));
  }
}

.containers {
  display: flex;
  justify-content: center;
  align-items: center;
 margin-top: -230px;
 margin-right: 15%;
 gap: 15%;
}
.abutfootersub{
  width: 100%;
  height: 212px;
  left: 0px;
  top: 35px; 
  position: relative; 
  padding: 180px 60px;
  display: flex;
  justify-Content: center;
  align-Items: center;
  border-radius: 20px;
}

