.RD_Grant-mobile{
    background: '#F9F7F1';
    padding: 10px;
    margin-left: 10px;
  }
.RD_Grant-desktop{
    background: '#F9F7F1';
    padding: 50px;
    margin-left: 50px;
  }
  .RD_Grant_txt-mobile{
    width: 70vw;
    min-height: 70vh;
    position: relative;
  }
  
  .RD_Grant_txt-desktop{

    width: 70vw;
    min-height: 30vh;
    position: relative;
  }
  .RD_grant_1-mobile{
    color: black; 
    font-Size: 25px; 
    font-family: 'Neuwelt'; 
    font-Weight: 700; 
    letter-Spacing: 0.25px; 
  }
  .RD_grant_1-desktop{
    margin-left:0%;


    color: black; 
    font-Size: 30px; 
    font-family: 'Neuwelt'; 
    font-Weight: 700; 
    letter-Spacing: 0.25px; 
  }
  .RD_grant_s-mobile{
    color: black; 
    font-Size: 25px; 
    font-family: 'Neuwelt'; 
    font-Weight: 700; 
    letter-Spacing: 0.25px; 
  }
  .RD_grant_s-desktop{
    margin-left:0%;
padding-top: 20px;

    color: black; 
    font-Size: 25px; 
    font-family: 'Neuwelt'; 
    font-Weight: 700; 
    letter-Spacing: 0.25px; 
  }
  .RD_grant_1_1-mobile{
    color: black; 
    font-Size: 25px; 
    font-family: 'Neuwelt'; 
    font-Weight: 700; 
    letter-Spacing: 0.25px; 
  }
  .RD_grant_1_1-desktop{
    margin-left:0%;
    padding-top: 20px;


    color: black; 
    font-Size: 20px; 
    font-family: 'Neuwelt'; 
    font-Weight: 700; 
    letter-Spacing: 0.25px; 
  }
  .RD_grant_2-mobile{
    width: 90vw;
    left: 0px; 
    top: 80px; 
    position: absolute; 
    color: black; 
    font-size: 17px;
    font-Family: 'Neuwelt Light'; 
    font-Weight: 400; 
    letter-Spacing: 1px; 
    line-height: 1.5;
    text-align: justify;
  }
  .RD_grant_2-desktop{
    width: 85vw;
    left: 0px; 
    top: 96px; 
    position: absolute; 
    color: black; 
    font-size: 19px;
    font-Family: 'Neuwelt Light'; 
    font-Weight: 400; 
    letter-Spacing: 1px; 
    line-height: 1.5;
    text-align: justify;
    
  }
  .RD_grant_2_2-mobile{
    width: 90vw;
    left: 0px; 
    top: 0px; 
    position: relative; 
    color: black; 
    font-size: 17px;
    font-Family: 'Neuwelt Light'; 
    font-Weight: 400; 
    letter-Spacing: 1px; 
    line-height: 1.5;
    text-align: justify;
  }
  .RD_grant_2_2-desktop{
    width: 85vw;
    left: 0px; 
    top: 26px; 
    position: absolute; 
    color: black; 
    font-size: 19px;
    font-Family: 'Neuwelt Light'; 
    font-Weight: 400; 
    letter-Spacing: 1px; 
    line-height: 1.5;
    text-align: justify;
    
  }
  .RD_grant_3-mobile{
    width: 90vw;
    left: 0px; 
    top: 120px; 
    position: relative; 
    color: black; 
    font-size: 17px;
    font-Family: 'Neuwelt Light'; 
    font-Weight: 400; 
    letter-Spacing: 1px; 
    line-height: 1.5;
    text-align: justify;
  }
  .RD_grant_3-desktop{
    width: 85vw;
    left: 0px; 
    top: 96px; 
    position: relative; 
    color: black; 
    font-size: 19px;
    font-Family: 'Neuwelt Light'; 
    font-Weight: 400; 
    letter-Spacing: 1px; 
    line-height: 1.5;
    text-align: justify;
    
  }

  .RD_grant_4-mobile{
    width: 90vw;
    left: 0px; 
    top: 10px; 
    position: relative; 
    color: black; 
    font-size: 17px;
    font-Family: 'Neuwelt Light'; 
    font-Weight: 400; 
    letter-Spacing: 1px; 
    line-height: 1.5;
    text-align: justify;
  }
  .RD_grant_4-desktop{
    width: 85vw;
    left: 0px; 
    top: 96px; 
    position: relative; 
    color: black; 
    font-size: 19px;
    font-Family: 'Neuwelt Light'; 
    font-Weight: 400; 
    letter-Spacing: 1px; 
    line-height: 1.5;
    text-align: justify;
    
  }
  .RD_grant_4_4-mobile{
    width: 90vw;
    left: 0px; 
    top: 10px; 
    position: relative; 
    color: black; 
    font-size: 17px;
    font-Family: 'Neuwelt Light'; 
    font-Weight: 400; 
    letter-Spacing: 1px; 
    line-height: 1.5;
    text-align: justify;
  }
  .RD_grant_4_4-desktop{
    width: 85vw;
    left: 0px; 
    top: 26px; 
    position: relative; 
    color: black; 
    font-size: 19px;
    font-Family: 'Neuwelt Light'; 
    font-Weight: 400; 
    letter-Spacing: 1px; 
    line-height: 1.5;
    text-align: justify;
    
  }